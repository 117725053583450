import {
  Component,
  OnInit,
  ViewChild,
  DoCheck,
  ChangeDetectorRef,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { getQuoteService } from '../services/get-quote/get-quote.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CommonService,
  UnitedStatesDateParserFormatter,
} from '../../app/services/common.service';
import { PriceBrackupService } from '../services/PRiceBrackup/price-brackeup.service';
import { ShipmentListingService } from '../services/shipment-listing.service';
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { AddFavoriteComponent } from 'src/app/_helpers/popup-dialog/add-favorite/add-favorite.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../environments/environment';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { ListDataServiceService } from '../services/ListData/list-data-service.service';
import { data } from 'jquery';
import { ViewPodComponent } from '../_helpers/popup-dialog/view-pod/view-pod.component';
import { LoadingService } from '../services/loader.service';
@Component({
  selector: 'app-shipment',
  styleUrls: ['./shipment.component.css'],
  templateUrl: './shipment.component.html',
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: UnitedStatesDateParserFormatter,
    },
  ],
})
export class ShipmentComponent implements OnInit, DoCheck, OnDestroy {
  // element Refs for date picker

  @ViewChild('endPicker') pickerEnd;
  @ViewChild('startPicker') pickerStart;
  //********************************************* */
  modelFromDate: NgbDateStruct;
  modelEndDate: NgbDateStruct;
  shipments: any;
  page = 0;
  paginationSize: number;
  displayCourier: boolean;
  totalPages: any;
  totalElements: any;
  filterCourier: any;
  perPage: number;

  currentPage: any;
  popOverDataPrice: any;
  statusData: any;
  hoveredDate: NgbDate | null = null;
  sortBy: any;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  isDesc: boolean = false;
  column: string = 'Order';
  searchKey: string;
  queryStringParam: string;
  dateFilterForm: any;
  startDate: any;
  endDate: any;
  cYear: any;
  Orders: boolean;
  transit: boolean;
  completed: boolean;
  scheduled: boolean;
  shipmentStatus = '';
  selectedQuoteID: any;
  selectedOrderID: any;
  selectedStatus: any;
  shipperZip = '';
  consigneeZip = '';
  trackingData: any;
  sidebarToggle: boolean;
  selectedTabId: any = 1;
  proBill: any;
  headingArray: any = [
    { value: 'All Orders', id: 1 },
    { value: 'In-Transit Orders', id: 2 },
    { value: 'Completed Orders', id: 3 },
    { value: 'Scheduled Orders', id: 4 },
    { value: 'Failed Orders', id: 5 },
  ];
  endOptions: FlatpickrOptions = {
    mode: 'single',
    dateFormat: 'm-d-Y',
    defaultDate: null,
  };
  startOptions: FlatpickrOptions = {
    mode: 'single',
    dateFormat: 'm-d-Y',
    defaultDate: null,
  };
  isDashBoardView: boolean = false;
  shippingMode: string;
  shippingCountryParam: string;
  carrierId: string;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    public quote: getQuoteService,
    private modalService: NgbModal,
    public priceBr: PriceBrackupService,
    public common: CommonService,
    private router: Router,
    public config: NgbPaginationConfig,
    private toast: ToastrService,
    private shipment: ShipmentListingService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private formBuilder: FormBuilder,
    private listDataService: ListDataServiceService,
    private fb: FormBuilder,
    private _loaderService: LoadingService
  ) {
    this.config.size = 'sm';
    this.changeDetector.detach();
  }

  ngDoCheck() {
    if (this.common.sidebarToggle == true) {
      this.sidebarToggle = true;
    } else {
      this.sidebarToggle = false;
    }
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {
    this.startDate = this.activatedRoute.snapshot.paramMap.get('fromDate');
    this.endDate = this.activatedRoute.snapshot.paramMap.get('toDate');
    let dashBoardView =
      this.activatedRoute.snapshot.paramMap.get('isDashBoardView');

    console.log(
      this.isDashBoardView,
      'isDashBoardView',
      this.startDate,
      this.endDate
    );
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['status'] === 'UNSUCCESSFUL') {
        this.selectedTabId = 5;
        this.shipmentStatus = 'UNSUCCESSFUL';
      } else if (params['status'] === 'LTL') {
        this.selectedTabId = 1;
        this.queryStringParam = 'LTL';
      } else if (params['status'] === 'CRS') {
        this.selectedTabId = 1;
        this.queryStringParam = 'CRS';
      } else if (params['carrierId']) {
        this.carrierId = params['carrierId'];
      } else if (params['shippingCountry']) {
        this.selectedTabId = 1;
        this.shippingCountryParam = params['shippingCountry'];
      } else {
        this.selectedTabId = 1;
      }
    });

    if (dashBoardView) {
      this.isDashBoardView = true;
    }

    this._loaderService.stringSubject.next(true);
    this.addFevForm = this.fb.group({
      addFev: ['', [Validators.required]],
    });
    this.listDataService.removeAllDataOfQuoteHistory();
    this.listDataService.removeAllDataOfInvoiceList();
    this.listDataService.removeAllDataOfSettingUserManagementList();
    this.listDataService.removeAllDataOfAddressBookList();
    this.listDataService.removeAllDataOfShipcarteProList();
    this.listDataService.removeAllDataOfShipcarteProList();
    this.listDataService.removeAllDataOfShipcarteProProductList();
    this.listDataService.removeAllDataOfShipcarteProPackageList();
    this.cYear = environment.Cyear;
    this.sortBy = {
      orderId: 'down',
      poNumber: 'down',
      service: 'down',
      orderSource: 'down',
      pickupDate: 'down',
      shipper: 'down',
      origin: 'down',
      consignee: 'down',
      destination: 'down',
      price: 'down',
    };
    this.filterCourier = false;
    this.currentPage = 0;
    this.perPage = 20;
    this.searchKey = '';

    this.getShipmentitems(this.page, 20);

    this.dateFilterForm = this.formBuilder.group({
      filterKey: [''],
      startDate: ['', [Validators.required]],
      endDate: ['', Validators.required],
    });
  }
  // if (id == 2) {
  //   this.shipmentStatus = 'INTRANSIT'
  // } else if (id == 3) {
  //   this.shipmentStatus = 'DELIVERED'
  // } else if (id == 4) {
  //   this.shipmentStatus = 'NEW'
  // } else {
  //   this.shipmentStatus = ''
  // }
  checkDataIsPresentInServiceFileOrNot() {
    let dataFromServiceFile = this.listDataService.getAllDataOfOrderList();
    console.log(dataFromServiceFile);
    if (dataFromServiceFile.tabId > 0) {
      console.log(dataFromServiceFile);
      this.selectedTabId = dataFromServiceFile.tabId;
      if (dataFromServiceFile.tabId == 1) {
        if (dataFromServiceFile.allOrders.dataList.length > 0) {
          (this.isDataLoading = ''), (this.shipmentStatus = '');
          this.page = dataFromServiceFile.allOrders.pageCount;
          this.perPage = dataFromServiceFile.allOrders.pageSize;
          this.shipments = dataFromServiceFile.allOrders.dataList;
          if (dataFromServiceFile.allOrders.fitlerKey != '') {
            this.dateFilterForm.patchValue({
              filterKey: dataFromServiceFile.allOrders.fitlerKey,
            });
            this.queryStringParam = dataFromServiceFile.allOrders.fitlerKey;
          }
          if (dataFromServiceFile.allOrders.searchKey != '') {
            this.searchKey = dataFromServiceFile.allOrders.searchKey;
          }
          if (
            dataFromServiceFile.allOrders.fromDate &&
            dataFromServiceFile.allOrders.toDate
          ) {
            this.startDate = dataFromServiceFile.allOrders.fromDate;
            this.endDate = dataFromServiceFile.allOrders.toDate;
            this.modelFromDate = {
              year: parseInt(
                dataFromServiceFile.allOrders.fromDate.split('-')[0]
              ),
              month: parseInt(
                dataFromServiceFile.allOrders.fromDate.split('-')[1]
              ),
              day: parseInt(
                dataFromServiceFile.allOrders.fromDate.split('-')[2]
              ),
            };
            this.modelEndDate = {
              year: parseInt(
                dataFromServiceFile.allOrders.toDate.split('-')[0]
              ),
              month: parseInt(
                dataFromServiceFile.allOrders.toDate.split('-')[1]
              ),
              day: parseInt(dataFromServiceFile.allOrders.toDate.split('-')[2]),
            };
          }
          if (dataFromServiceFile.allOrders.selectedId != '') {
            this.scrollToViewMethod(
              dataFromServiceFile.tabId,
              dataFromServiceFile.allOrders.selectedId
            );
          }
        } else {
          this.queryStringParam = '';
          this.dateFilterForm.patchValue({
            filterKey: '',
          });
          this.searchKey = '';
          this.startDate = undefined;
          this.endDate = undefined;
          this.modelEndDate = undefined;
          this.modelFromDate = undefined;
          this.listDataService.removeDataOfListAccordingToTabIdInOrderList(1);
          this.getShipmentitems(this.page, this.perPage);
        }
        this.getAllKeyOfObj().forEach((element, index) => {
          this.sortBy[element] = 'down';
        });
        this.isAscending = dataFromServiceFile.allOrders.isAscending;
        if (dataFromServiceFile.allOrders.sortParam != '') {
          this.sortParam = dataFromServiceFile.allOrders.sortParam;
          this.sortBy[dataFromServiceFile.allOrders.sortParam] = this
            .isAscending
            ? 'up'
            : 'down';
        }
      } else if (dataFromServiceFile.tabId == 2) {
        if (dataFromServiceFile.intransitOrders.dataList.length > 0) {
          this.isDataLoading = '';
          this.shipmentStatus = 'INTRANSIT';
          this.page = dataFromServiceFile.intransitOrders.pageCount;
          this.perPage = dataFromServiceFile.intransitOrders.pageSize;
          this.shipments = dataFromServiceFile.intransitOrders.dataList;
          if (dataFromServiceFile.intransitOrders.fitlerKey != '') {
            this.dateFilterForm.patchValue({
              filterKey: dataFromServiceFile.intransitOrders.fitlerKey,
            });
            this.queryStringParam =
              dataFromServiceFile.intransitOrders.fitlerKey;
          }
          if (dataFromServiceFile.intransitOrders.searchKey != '') {
            this.searchKey = dataFromServiceFile.intransitOrders.searchKey;
          }
          if (
            dataFromServiceFile.intransitOrders.fromDate &&
            dataFromServiceFile.intransitOrders.toDate
          ) {
            this.startDate = dataFromServiceFile.intransitOrders.fromDate;
            this.endDate = dataFromServiceFile.intransitOrders.toDate;
            this.modelFromDate = {
              year: parseInt(
                dataFromServiceFile.intransitOrders.fromDate.split('-')[0]
              ),
              month: parseInt(
                dataFromServiceFile.intransitOrders.fromDate.split('-')[1]
              ),
              day: parseInt(
                dataFromServiceFile.intransitOrders.fromDate.split('-')[2]
              ),
            };
            this.modelEndDate = {
              year: parseInt(
                dataFromServiceFile.intransitOrders.toDate.split('-')[0]
              ),
              month: parseInt(
                dataFromServiceFile.intransitOrders.toDate.split('-')[1]
              ),
              day: parseInt(
                dataFromServiceFile.intransitOrders.toDate.split('-')[2]
              ),
            };
          }
          if (dataFromServiceFile.intransitOrders.selectedId != '') {
            this.scrollToViewMethod(
              dataFromServiceFile.tabId,
              dataFromServiceFile.intransitOrders.selectedId
            );
          }
        } else {
          this.queryStringParam = '';
          this.dateFilterForm.patchValue({
            filterKey: '',
          });
          this.searchKey = '';
          this.startDate = undefined;
          this.endDate = undefined;
          this.modelEndDate = undefined;
          this.modelFromDate = undefined;
          this.listDataService.removeDataOfListAccordingToTabIdInOrderList(2);
          this.getShipmentitems(this.page, this.perPage);
        }
        this.getAllKeyOfObj().forEach((element, index) => {
          this.sortBy[element] = 'down';
        });
        this.isAscending = dataFromServiceFile.intransitOrders.isAscending;
        if (dataFromServiceFile.intransitOrders.sortParam != '') {
          this.sortParam = dataFromServiceFile.intransitOrders.sortParam;
          this.sortBy[dataFromServiceFile.intransitOrders.sortParam] = this
            .isAscending
            ? 'up'
            : 'down';
        }
      } else if (dataFromServiceFile.tabId == 3) {
        if (dataFromServiceFile.completedOrders.dataList.length > 0) {
          this.isDataLoading = '';
          this.shipmentStatus = 'DELIVERED';
          this.page = dataFromServiceFile.completedOrders.pageCount;
          this.perPage = dataFromServiceFile.completedOrders.pageSize;
          this.shipments = dataFromServiceFile.completedOrders.dataList;
          if (dataFromServiceFile.completedOrders.fitlerKey != '') {
            this.dateFilterForm.patchValue({
              filterKey: dataFromServiceFile.completedOrders.fitlerKey,
            });
            this.queryStringParam =
              dataFromServiceFile.completedOrders.fitlerKey;
          }
          if (dataFromServiceFile.completedOrders.searchKey != '') {
            this.searchKey = dataFromServiceFile.completedOrders.searchKey;
          }
          if (
            dataFromServiceFile.completedOrders.fromDate &&
            dataFromServiceFile.completedOrders.toDate
          ) {
            this.startDate = dataFromServiceFile.completedOrders.fromDate;
            this.endDate = dataFromServiceFile.completedOrders.toDate;
            this.modelFromDate = {
              year: parseInt(
                dataFromServiceFile.completedOrders.fromDate.split('-')[0]
              ),
              month: parseInt(
                dataFromServiceFile.completedOrders.fromDate.split('-')[1]
              ),
              day: parseInt(
                dataFromServiceFile.completedOrders.fromDate.split('-')[2]
              ),
            };
            this.modelEndDate = {
              year: parseInt(
                dataFromServiceFile.completedOrders.toDate.split('-')[0]
              ),
              month: parseInt(
                dataFromServiceFile.completedOrders.toDate.split('-')[1]
              ),
              day: parseInt(
                dataFromServiceFile.completedOrders.toDate.split('-')[2]
              ),
            };
          }
          if (dataFromServiceFile.completedOrders.selectedId != '') {
            this.scrollToViewMethod(
              dataFromServiceFile.tabId,
              dataFromServiceFile.completedOrders.selectedId
            );
          }
        } else {
          this.queryStringParam = '';
          this.dateFilterForm.patchValue({
            filterKey: '',
          });
          this.searchKey = '';
          this.startDate = undefined;
          this.endDate = undefined;
          this.modelEndDate = undefined;
          this.modelFromDate = undefined;
          this.listDataService.removeDataOfListAccordingToTabIdInOrderList(3);
          this.getShipmentitems(this.page, this.perPage);
        }
        this.getAllKeyOfObj().forEach((element, index) => {
          this.sortBy[element] = 'down';
        });
        this.isAscending = dataFromServiceFile.completedOrders.isAscending;
        // debugger
        if (dataFromServiceFile.completedOrders.sortParam != '') {
          this.sortParam = dataFromServiceFile.completedOrders.sortParam;
          this.sortBy[dataFromServiceFile.completedOrders.sortParam] = this
            .isAscending
            ? 'up'
            : 'down';
        }
      } else if (dataFromServiceFile.tabId == 4) {
        if (dataFromServiceFile.scheduledOrders.dataList.length > 0) {
          this.isDataLoading = '';
          this.shipmentStatus = 'NEW';
          this.page = dataFromServiceFile.scheduledOrders.pageCount;
          this.perPage = dataFromServiceFile.scheduledOrders.pageSize;
          this.shipments = dataFromServiceFile.scheduledOrders.dataList;
          if (dataFromServiceFile.scheduledOrders.fitlerKey != '') {
            this.dateFilterForm.patchValue({
              filterKey: dataFromServiceFile.scheduledOrders.fitlerKey,
            });
            this.queryStringParam =
              dataFromServiceFile.scheduledOrders.fitlerKey;
          }
          if (dataFromServiceFile.scheduledOrders.searchKey != '') {
            this.searchKey = dataFromServiceFile.scheduledOrders.searchKey;
          }
          if (
            dataFromServiceFile.scheduledOrders.fromDate &&
            dataFromServiceFile.scheduledOrders.toDate
          ) {
            this.startDate = dataFromServiceFile.scheduledOrders.fromDate;
            this.endDate = dataFromServiceFile.scheduledOrders.toDate;
            this.modelFromDate = {
              year: parseInt(
                dataFromServiceFile.scheduledOrders.fromDate.split('-')[0]
              ),
              month: parseInt(
                dataFromServiceFile.scheduledOrders.fromDate.split('-')[1]
              ),
              day: parseInt(
                dataFromServiceFile.scheduledOrders.fromDate.split('-')[2]
              ),
            };
            this.modelEndDate = {
              year: parseInt(
                dataFromServiceFile.scheduledOrders.toDate.split('-')[0]
              ),
              month: parseInt(
                dataFromServiceFile.scheduledOrders.toDate.split('-')[1]
              ),
              day: parseInt(
                dataFromServiceFile.scheduledOrders.toDate.split('-')[2]
              ),
            };
          }
          if (dataFromServiceFile.scheduledOrders.selectedId != '') {
            this.scrollToViewMethod(
              dataFromServiceFile.tabId,
              dataFromServiceFile.scheduledOrders.selectedId
            );
          }
        } else {
          this.queryStringParam = '';
          this.dateFilterForm.patchValue({
            filterKey: '',
          });
          this.searchKey = '';
          this.startDate = undefined;
          this.endDate = undefined;
          this.modelEndDate = undefined;
          this.modelFromDate = undefined;
          this.listDataService.removeDataOfListAccordingToTabIdInOrderList(4);
          this.getShipmentitems(this.page, this.perPage);
        }
        this.getAllKeyOfObj().forEach((element, index) => {
          this.sortBy[element] = 'down';
        });
        this.isAscending = dataFromServiceFile.scheduledOrders.isAscending;
        if (dataFromServiceFile.scheduledOrders.sortParam != '') {
          this.sortParam = dataFromServiceFile.scheduledOrders.sortParam;
          this.sortBy[dataFromServiceFile.scheduledOrders.sortParam] = this
            .isAscending
            ? 'up'
            : 'down';
        }
      } else if (dataFromServiceFile.tabId == 5) {
        if (dataFromServiceFile.failedOrders.dataList.length > 0) {
          this.isDataLoading = '';
          this.shipmentStatus = 'UNSUCCESSFUL';
          this.page = dataFromServiceFile.failedOrders.pageCount;
          this.perPage = dataFromServiceFile.failedOrders.pageSize;
          this.shipments = dataFromServiceFile.failedOrders.dataList;
          if (dataFromServiceFile.failedOrders.fitlerKey != '') {
            this.dateFilterForm.patchValue({
              filterKey: dataFromServiceFile.failedOrders.fitlerKey,
            });
            this.queryStringParam = dataFromServiceFile.failedOrders.fitlerKey;
          }
          if (dataFromServiceFile.failedOrders.searchKey != '') {
            this.searchKey = dataFromServiceFile.failedOrders.searchKey;
          }
          if (
            dataFromServiceFile.failedOrders.fromDate &&
            dataFromServiceFile.failedOrders.toDate
          ) {
            this.startDate = dataFromServiceFile.failedOrders.fromDate;
            this.endDate = dataFromServiceFile.failedOrders.toDate;
            this.modelFromDate = {
              year: parseInt(
                dataFromServiceFile.failedOrders.fromDate.split('-')[0]
              ),
              month: parseInt(
                dataFromServiceFile.scheduledOrders.fromDate.split('-')[1]
              ),
              day: parseInt(
                dataFromServiceFile.scheduledOrders.fromDate.split('-')[2]
              ),
            };
            this.modelEndDate = {
              year: parseInt(
                dataFromServiceFile.failedOrders.toDate.split('-')[0]
              ),
              month: parseInt(
                dataFromServiceFile.scheduledOrders.toDate.split('-')[1]
              ),
              day: parseInt(
                dataFromServiceFile.scheduledOrders.toDate.split('-')[2]
              ),
            };
          }
          if (dataFromServiceFile.failedOrders.selectedId != '') {
            this.scrollToViewMethod(
              dataFromServiceFile.tabId,
              dataFromServiceFile.failedOrders.selectedId
            );
          }
        } else {
          this.queryStringParam = '';
          this.dateFilterForm.patchValue({
            filterKey: '',
          });
          this.searchKey = '';
          this.startDate = undefined;
          this.endDate = undefined;
          this.modelEndDate = undefined;
          this.modelFromDate = undefined;
          this.listDataService.removeDataOfListAccordingToTabIdInOrderList(4);
          this.getShipmentitems(this.page, this.perPage);
        }
        this.getAllKeyOfObj().forEach((element, index) => {
          this.sortBy[element] = 'down';
        });
        this.isAscending = dataFromServiceFile.failedOrders.isAscending;
        if (dataFromServiceFile.failedOrders.sortParam != '') {
          this.sortParam = dataFromServiceFile.failedOrders.sortParam;
          this.sortBy[dataFromServiceFile.failedOrders.sortParam] = this
            .isAscending
            ? 'up'
            : 'down';
        }
      }
      setTimeout(() => {
        this._loaderService.stringSubject.next(false);
      }, 1000);
    } else {
      this.getShipmentitems(this.page, this.perPage);
    }
  }
  scrollToViewMethod(tabId: number, selectedItemId: string): void {
    setTimeout(() => {
      const element = document.getElementById(selectedItemId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 500);
  }
  allOrders(id?) {
    // debugger
    this.page = 0;
    this.searchKey = '';
    this.dateFilterForm.patchValue({
      filterKey: '',
    });
    this.queryStringParam = '';
    this.startDate = undefined;
    this.endDate = undefined;
    this.modelFromDate = undefined;
    this.modelEndDate = undefined;
    this.selectedTabId = id;
    if (id == 2) {
      this.shipmentStatus = 'INTRANSIT';
    } else if (id == 3) {
      this.shipmentStatus = 'DELIVERED';
    } else if (id == 4) {
      this.shipmentStatus = 'NEW';
    } else if (id == 5) {
      this.shipmentStatus = 'UNSUCCESSFUL';
    } else {
      this.shipmentStatus = '';

    }
    this.sortParam = '';
    this.isAscending = false;

    this.getShipmentitems(this.page, 20);


    // this.listDataService.setTabIdOfOrderList(this.selectedTabId);
    // this.checkDataIsPresentInServiceFileOrNot();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  saveToFavorite(orderId) {
    const modalRef = this.modalService.open(AddFavoriteComponent, {
      size: 'lg',
      centered: false,
      backdrop: true,
      animation: true,
      windowClass: 'my-custom-modal8',
    });
    modalRef.componentInstance.orderId = orderId;
    modalRef.result.then((result) => {
      if (result && result.success) {
        this.toast.success(
          'Shipment Successfully added to favorites.',
          'Success',
          {
            timeOut: 10000,
          }
        );
      }
    });
  }
  getAllKeyOfObj() {
    return Object.keys(this.sortBy);
  }
  sort(property) {
    this.getAllKeyOfObj().forEach((element, index) => {
      console.log(element, property);
      if (element != property) {
        this.sortBy[element] = 'down';
      }
    });
    console.log(this.sortBy);
    if (this.sortBy[property] == 'down') {
      this.sortBy[property] = 'up';
      this.isAscending = true;
    } else {
      this.sortBy[property] = 'down';
      this.isAscending = false;
    }
    this.sortParam = property;
    this.listDataService.setDataOfSortValueInOrderList(
      this.isAscending,
      this.sortParam,
      this.selectedTabId
    );
    this.getShipmentitems(0, (this.page + 1) * 20);
    // this.isDesc = !this.isDesc; //change the direction
    // this.column = property;
    // let direction = this.isDesc ? 1 : -1;
    // this.sortBy[property] = !this.isDesc ? "down" : "up";
    // if (property === 'price') {
    //   this.shipments.sort(function (a, b) {
    //     if (parseFloat(a[property]) < parseFloat(b[property])) {
    //       return -1 * direction;
    //     }
    //     else if (parseFloat(a[property]) > parseFloat(b[property])) {
    //       return 1 * direction;
    //     }
    //     else {
    //       return 0;
    //     }
    //   });
    // } else {
    //   this.shipments.sort(function (a, b) {
    //     let one = a[property] ? a[property] : ''
    //     let two = b[property] ? b[property] : ''

    //     if (one.toLowerCase() < two.toLowerCase()) {
    //       return -1 * direction;
    //     }
    //     else if (one.toLowerCase() > two.toLowerCase()) {
    //       return 1 * direction;
    //     }
    //     else {
    //       return 0;
    //     }
    //   });
    // }
  }
  getQuote(i) {
    this.router.navigate([
      '/get-quote/' + this.shipments[i]['quoteDetails']['quoteId'],
    ]);
  }
  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }
  isDataLoading: any = '';
  isAscending: boolean = false;
  sortParam: any = '';
  getShipmentitems(page, perpage) {
    this.allDataLoaded = false;
    this.isDataLoading = '';
    this._loaderService.stringSubject.next(true);
    let url = '?isAscending=' + this.isAscending;
    if (this.sortParam != '') {
      url = url + '&sortParam=' + this.sortParam;
    }

    if (this.carrierId != '' && this.carrierId !== undefined) {
      url = url + '&carrierId=' + this.carrierId;
    }
    if (this.shipmentStatus != '') {
      url = url + '&shipmentStatus=' + this.shipmentStatus;
    }
    if (this.queryStringParam) {
      url = url + '&shippingMode=' + this.queryStringParam;
    }

    if (this.shippingCountryParam) {
      url = url + '&shippingCountry=' + this.shippingCountryParam;
    }

    if (this.searchKey != '' && this.searchKey != undefined) {
      url = url + '&searchKey=' + this.searchKey;
    }
    if (this.startDate && this.endDate) {
      url =
        url +
        '&startDate=' +
        this.formatDate(this.startDate) +
        '&endDate=' +
        this.formatDate(this.endDate);
    }

    if (this.isDashBoardView) {
      url = url + '&isDashBoardView=' + this.isDashBoardView;
    }

    this.shipment.getShipmentItems(page, perpage, url).subscribe(
      (ship) => {
        this.isDataLoading = '';
        if (ship['response'].length <= 0 && this.page == 0) {
          let data = {
            pageCount: this.page,
            pageSize: this.perPage,
            dataList: ship['response'],
          };
          this.listDataService.setAllDataOfOrderList(data, this.selectedTabId);
          this.isDataLoading = 'No Data Found';
        } else {
          ship['response'].forEach((element, index) => {
            ship['response'][index]['poNumber'] = element
              ? element.poNumber
                ? element.poNumber
                : ''
              : '';
            ship['response'][index]['service'] = element.quoteDetails
              ? element.quoteDetails.shipmentMode
                ? element.quoteDetails.shipmentMode
                : ''
              : '';
            ship['response'][index]['pickupDate'] = element.quoteDetails
              ? element.quoteDetails.pickupDate
                ? element.quoteDetails.pickupDate
                : ''
              : '';
            (ship['response'][index]['shipperName'] = element.quoteDetails
              .shipperCompany
              ? element.quoteDetails.shipperCompany
              : ''),
              (ship['response'][index]['shipperOrigin'] = element.quoteDetails
                .shipperAddress.city
                ? element.quoteDetails.shipperAddress.city
                : '' + ' ' + element.quoteDetails.shipperAddress.state
                ? element.quoteDetails.shipperAddress.state
                : ''),
              (ship['response'][index]['consigneeName'] = element.quoteDetails
                .consigneeCompany
                ? element.quoteDetails.consigneeCompany
                : '');
            ship['response'][index]['consigneeOrigin'] = element.quoteDetails
              .consigneeAddress.city
              ? element.quoteDetails.consigneeAddress.city
              : '' + ' ' + element.quoteDetails.consigneeAddress.state
              ? element.quoteDetails.consigneeAddress.state
              : '';
            ship['response'][index]['price'] = element.quoteDetails.totalAmount
              ? element.quoteDetails.totalAmount
              : 0;
          });
          this.shipments = ship['response'];
          let data = {
            pageCount: this.page,
            pageSize: this.perPage,
            dataList: ship['response'],
          };
          this.listDataService.setAllDataOfOrderList(data, this.selectedTabId);
        }
        if (ship) {
          this._loaderService.stringSubject.next(false);
        }


      },
      (error) => {
        this._loaderService.stringSubject.next(false);
        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin']);
        }
        this.isDataLoading = 'Something Went Wrong! Please try Again Later.';
      }
    );
  }

  popOverIndex(i) {
    this.priceBr
      .getPRicebrackup(this.shipments[i]['quoteDetails']['quoteId'])
      .subscribe(
        (price) => {
          this.popOverDataPrice = [];
          this.popOverDataPrice = price;
          this.popOverDataPrice['customId'] =
            this.shipments[i]['quoteDetails'].customQuoteId;
          this.popOverDataPrice['shpmtModeName'] =
            this.shipments[i]['quoteDetails'].shipmentModeName;
          this.popOverDataPrice['shipmentModeStyleCode'] =
            this.shipments[i]['quoteDetails'].shipmentModeStyleCode;
          var charges = [];

          if (this.popOverDataPrice['charges']) {
            this.popOverDataPrice.charges.forEach((item, index) => {
              for (var key of Object.keys(item)) {
                charges.push([key, item[key]]);
              }
            });
          }
          this.popOverDataPrice['price_breakUp'] = charges;
          var totalWeight = 0;
          this.shipments[i]['quoteDetails']['items'].forEach((item, index) => {
            totalWeight +=
              (item.wghtUnit.toLowerCase() == 'lbs'
                ? item.totalWeight
                : item.totalWeight / 2.2
              ).toFixed(2) * item.qty;
          });
          this.popOverDataPrice['weight'] = totalWeight;
        },
        (err) => {
          this.popOverDataPrice = [];
        }
      );
  }

  showPOD(quoteid, orderId, orderStatus) {
    this.selectedQuoteID = quoteid;
    this.selectedOrderID = orderId;
    this.selectedStatus = orderStatus;

    this.quote.getTrackingData(orderId).subscribe((response) => {
      this.trackingData = response;
    });
  }
  ViewDetailsON(quoteid, orderId, orderStatus) {
    this.selectedQuoteID = quoteid;
    this.selectedOrderID = orderId;
    this.selectedStatus = orderStatus;
    this.shipments.filter((element) => {
      if (element.orderId == orderId) {
        this.shipperZip = element.quoteDetails.shipperAddress.zipCode;
        this.consigneeZip = element.quoteDetails.consigneeAddress.zipCode;
      }
    });
    this.quote.getTrackingData(orderId).subscribe((response) => {
      this.trackingData = response;
    });
  }
  viewPOD() {
    this.common.viewPOD = true;
    this.router.navigate([
      '/shipment/details/' +
        this.selectedQuoteID +
        '/' +
        this.selectedOrderID +
        '/' +
        this.selectedStatus,
    ]);
  }
  viewDetails() {
    this.common.viewDetails = true;
    this.router.navigate([
      '/shipment/details/' +
        this.selectedQuoteID +
        '/' +
        this.selectedOrderID +
        '/' +
        this.selectedStatus,
    ]);
  }
  status(i) {
    this.statusData = this.shipments[i];
  }

  filterByValue(event) {
    this.queryStringParam = event.target.value;
    this.listDataService.setFilterValueInOrderList(
      event.target.value,
      this.selectedTabId
    );
    this.getShipmentitems(this.page, this.perPage);
  }

  viewQuote(source, quoteid, customOrId, orderId, orderStatus, proBill) {
    source = source.toLowerCase();
    source = source.replace(/\s/g, '-');
    this.listDataService.setSelectedItemIdInOrderList(
      orderId,
      this.selectedTabId
    );
    this.router.navigate([
      '/shipment/details/' +
        source +
        '/' +
        quoteid +
        '/' +
        customOrId +
        '/' +
        orderId +
        '/' +
        orderStatus,
    ]);
  }

  handleOrderClick(order: any, index: number) {
    this.viewQuote(
      order.source,
      order.quoteDetails.quoteId,
      order.customOrderId,
      order.orderId,
      order.status,
      order.proBill
    );
    sessionStorage.setItem('parentQuoteId', order.quoteDetails.quoteId);
    sessionStorage.setItem('parentCustomOrderId', order.customOrderId);
    sessionStorage.setItem('probillNo', order.proBill);
    console.log('Setting customOrderId', order.customOrderId);
    console.log('Setting parentQuoteId:', order.quoteDetails.quoteId);
    console.log('probill no:', order.proBill);

    if (order.quoteType === 'Multiple' && order.quoteDetails.quoteId) {
      this.router.navigate([
        '/shipment/child-shipment/',
        order.quoteDetails.quoteId,
      ]);
    }
  }

  timer: any;
  updateSearchKey(searchKey) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (searchKey.length >= 3) {
        this.page = 0;
        this.listDataService.setSearchKeyOfOrderList(
          searchKey,
          this.selectedTabId
        );
        this.searchKey = searchKey;
        this.getShipmentitems(this.page, this.perPage);
      } else if (searchKey.length == 0) {
        this.page = 0;
        this.listDataService.setSearchKeyOfOrderList('', this.selectedTabId);
        this.searchKey = '';
        this.getShipmentitems(this.page, this.perPage);
      }
    }, 500);
  }

  // Date format code for ng-2 date picker

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  apply() {
    let startDate = this.dateFilterForm['value'].startDate;
    let endDate = this.dateFilterForm['value'].endDate;
    if (startDate && endDate) {
      this.startDate =
        startDate.year + '-' + startDate.month + '-' + startDate.day;
      this.endDate = endDate.year + '-' + endDate.month + '-' + endDate.day;

      let d1 = Date.parse(this.startDate);
      let d2 = Date.parse(this.endDate);

      if (d1 > d2) {
        this.toast.error('Please provide valid Dates.', '', {
          timeOut: 10000,
        });
        return;
      }
      this.listDataService.setDateFilterOfOrderList(
        this.startDate,
        this.endDate,
        this.selectedTabId
      );
      this.page = 0;
      this.getShipmentitems(this.page, this.perPage);
    }
  }

  toggle(type) {
    if (type == 'start') {
      this.pickerStart.flatpickr.toggle();
    } else if (type == 'end') {
      this.pickerEnd.flatpickr.toggle();
    }
  }
  cancelOrder(data, index) {
    this.displayCourier = true;
    this._loaderService.stringSubject.next(true);
    this.shipment.cancelOrder(data.orderId).subscribe(
      (res) => {
        this.toast.success('Order Cancelled Successfully');
        //update the status of oder in all order list

        //if status=='intransit' then remove elemnt from intransit list
        this.shipments[index].status = 'CANCELLED';
        if (this.selectedTabId == 2) {
          this.listDataService.removeElementFromListByOrderIdAndTabId(
            data.orderId,
            2
          );
        }
        //if status=='sched' then remove element from scheduled list
        if (this.selectedTabId == 4) {
          this.listDataService.removeElementFromListByOrderIdAndTabId(
            data.orderId,
            4
          );
        }
        this.listDataService.updateTheStatusOfOrderssList(
          'CANCELLED',
          data.orderId,
          1
        );

        this.displayCourier = false;
        this._loaderService.stringSubject.next(false);
      },
      (err) => {
        this.toast.error('Something Went Wrong!');
        this.displayCourier = false;
        this._loaderService.stringSubject.next(false);
      }
    );
  }

  resetFilter() {
    if (
      this.searchKey != '' ||
      this.queryStringParam ||
      (this.modelFromDate && this.modelEndDate)
    ) {
      this.searchKey = '';
      this.dateFilterForm.patchValue({
        filterKey: '',
      });
      this.queryStringParam = '';
      this.startDate = undefined;
      this.endDate = undefined;
      this.modelFromDate = undefined;
      this.modelEndDate = undefined;
      this.dateFilterForm.get('startDate').value = undefined;
      this.listDataService.removeDataOfListAccordingToTabIdInOrderList(
        this.selectedTabId
      );
      this.checkDataIsPresentInServiceFileOrNot();
    }
  }
  isLoading: boolean = false;
  @HostListener('window:scroll', ['$event'])
  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;
  showButton: boolean = false;
  ngAfterViewInit() {
    this.scrollChangeCallback = () => this.scrollHandlerOrderList(event);
    window.addEventListener('scroll', this.scrollChangeCallback, true);
  }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollChangeCallback, true);
    console.log(this.scrollChangeCallback);
  }
  allDataLoaded: any = false;
  scrollHandlerOrderList(e) {
    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;

    if (scroll > this.currentPosition) {
      this.showButton = false;
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        !this.isLoading
      ) {
        this.isLoading = true;
        if (!this.allDataLoaded) {
          this.fetchListOfDataToAppend();
        } else {
          this.isLoading = false;
        }
      }
    } else {
      this.showButton = true;
    }
    this.currentPosition = scroll;
  }
  fetchListOfDataToAppend() {
    this.page = this.page + 1;
    let url = '?isAscending=' + this.isAscending;
    if (this.sortParam) {
      url = url + '&sortParam=' + this.sortParam;
    }
    if (this.carrierId != '' && this.carrierId !== undefined) {
      url = url + '&carrierId=' + this.carrierId;
    }
    if (this.shipmentStatus) {
      url = url + '&shipmentStatus=' + this.shipmentStatus;
    }
    if (this.queryStringParam) {
      url = url + '&shippingMode=' + this.queryStringParam;
    }
    if (this.searchKey) {
      url = url + '&searchKey=' + this.searchKey;
    }

    if (this.shippingCountryParam) {
      url = url + '&shippingCountry=' + this.shippingCountryParam;
    }

    if (this.isDashBoardView) {
      url = url + '&isDashBoardView=' + this.isDashBoardView;
    }
    if (this.startDate && this.endDate) {
      url =
        url +
        '&startDate=' +
        this.formatDate(this.startDate) +
        '&endDate=' +
        this.formatDate(this.endDate);
    }
    // debugger
    this.shipment.getShipmentItems(this.page, this.perPage, url).subscribe(
      (ship) => {
        this.isLoading = false;
        if (ship['response'].length <= 0) {
          this.isLoading = false;
          this.allDataLoaded = true;
          return;
        } else {
          ship['response'].forEach((element, index) => {
            ship['response'][index]['poNumber'] = element
              ? element.poNumber
                ? element.poNumber
                : ''
              : '';
            ship['response'][index]['service'] = element.quoteDetails
              ? element.quoteDetails.shipmentMode
                ? element.quoteDetails.shipmentMode
                : ''
              : '';
            ship['response'][index]['pickupDate'] = element.quoteDetails
              ? element.quoteDetails.pickupDate
                ? element.quoteDetails.pickupDate
                : ''
              : '';
            (ship['response'][index]['shipperName'] = element.quoteDetails
              .shipperCompany
              ? element.quoteDetails.shipperCompany
              : ''),
              (ship['response'][index]['shipperOrigin'] = element.quoteDetails
                .shipperAddress.city
                ? element.quoteDetails.shipperAddress.city
                : '' + ' ' + element.quoteDetails.shipperAddress.state
                ? element.quoteDetails.shipperAddress.state
                : ''),
              (ship['response'][index]['consigneeName'] = element.quoteDetails
                .consigneeCompany
                ? element.quoteDetails.consigneeCompany
                : '');
            ship['response'][index]['consigneeOrigin'] = element.quoteDetails
              .consigneeAddress.city
              ? element.quoteDetails.consigneeAddress.city
              : '' + ' ' + element.quoteDetails.consigneeAddress.state
              ? element.quoteDetails.consigneeAddress.state
              : '';
            ship['response'][index]['price'] = element.quoteDetails.totalAmount
              ? element.quoteDetails.totalAmount
              : 0;
          });
          this.shipments = this.shipments.concat(ship['response']);
          let data = {
            pageCount: this.page,
            pageSize: this.perPage,
            dataList: this.shipments,
          };
          this.listDataService.setAllDataOfOrderList(data, this.selectedTabId);
        }
      },
      (error) => {
        this.isLoading = false;
        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin']);
        }
      }
    );
  }
  @ViewChild('loadContent') loadContent;
  tabStyle: number = 1;
  pleaseDataRemove: boolean = true;
  orderid: any;
  addFevForm: FormGroup;
  @ViewChild('confirmWarn') confirmWarn;
  savingToFavValue: string = '';
  changeStatusOfOrder(mode, value, i) {
    // this.modalService.open(this.loadContent, { centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'dark-modal', backdrop: 'static' })
    let key = '';
    if (mode === 'pickup') {
      key = 'PICKEDUP';
    } else if (mode === 'nopickup') {
      key = 'NO_PICKUP';
    } else if (mode === 'del') {
      key = 'DELIVERED';
    } else if (mode === 'notDel') {
      key = 'NO_DELIVERED';
    }
    let key2 = value.status;
    console.log(key2);
    this.quote.changeStatuOfOrder(value.orderId, key).subscribe((res: any) => {
      this.modalService.dismissAll();
      this.toast.success('Status Updated Successfully');
      this.shipments[i].status = res.data;

      //update data is all order list when we change the status of any order.
      if (this.tabStyle != 1) {
        setTimeout(() => {
          this.shipments.splice(i, 1);
        }, 500);
      }
    });
    // .catch((err: any) => {
    //   this.modalService.dismissAll()
    //   this.toast.error("Something Went Wrong! Please try again later.")
    // })
  }
  shipAgain(quoteId, backToQuote, orderId) {
    if (backToQuote == 'On Call Order') {
      return;
    } else if (backToQuote == 'Online') {
      this.router.navigate(['/get-quote/' + quoteId]);
    }
  }

  getOrderMetaDataById(
    quoteid,
    customOrId,
    orderId,
    orderStatus,
    customerName,
    customerid,
    status,
    carrierName,
    carrierLogo,
    orderSource,
    proBill
  ) {
    this.pleaseDataRemove = false;

    const userInforamation = {
      quoteid: quoteid,
      customOrderId: customOrId,
      proBill: proBill,
      orderId: orderId,
      orderStatus: orderStatus,
      customerName: customerName,
      customerId: customerid,
      carrierName: carrierName.carrierName,
      carrierLogo: carrierLogo,
      source: orderSource,
    };
    this.listDataService.setSelectedOrderId(orderId, this.tabStyle);
    this.common.quoteId = quoteid;
    sessionStorage.setItem('userInformation', JSON.stringify(userInforamation));
    this.router.navigate(['/get-quote/edit/', userInforamation.quoteid]);

    this.orderid = orderId;
    this.quote = quoteid;
  }
  open(content, type, orderId) {
    this.modalService
      .open(content, { size: 'sm', centered: true, windowClass: 'AddfevModal' })
      .result.then(
        (result) => {
          if (this.addFevForm.valid) {
            this.modalService
              .open(this.confirmWarn, {
                size: 'sm',
                centered: true,
                windowClass: 'confirmModal',
              })
              .result.then(
                (result) => {
                  this.postFevData(orderId);
                },
                (reason) => {}
              );
          } else {
            this.addFevForm.markAllAsTouched();
            this.open(content, '', orderId);
          }
        },
        (reason) => {}
      );
  }
  postFevData(orderId) {
    this.modalService.open(this.loadContent, {
      centered: true,
      backdropClass: 'light-blue-backdrop',
      windowClass: 'dark-modal',
      backdrop: 'static',
    });
    let payload = {
      orderId: orderId,
      isFavourite: true,
      favouriteOrderName: this.savingToFavValue,
    };
    this.shipment.updateItems(payload).subscribe((res) => {
      this.toast.success('Shipment Successfully added to favorites.');

      this.modalService.dismissAll();
    });
    // .catch((err) => {
    //   this.modalService.dismissAll();

    //   this.toast.error(err.message ? err.message : 'Something Went Wrong', '', {
    //   })
    // })
  }
  setValue(value: any) {
    this.savingToFavValue = value;
  }
  viewPod(data) {
    this.shipment.getPodDoc(data.orderId, data.ladingNo).subscribe(
      (response: any) => {
        if (response && response.downloadURL) {
          // const modalRef = this.modalService.open(ViewPodComponent, { size: 'lg', centered: false, backdrop: true, windowClass: 'viewPodModal', keyboard: true });

          // modalRef.componentInstance.pdfurl = response.downloadURL;

          const fileExtension = response.downloadURL.split('.').pop();

          // List of supported file extensions for preview
          const supportedFileExtensions = ['pdf', 'png', 'jpg', 'jpeg', 'gif']; // Add more extensions as needed

          // Check if the file extension is in the list of supported extensions
          if (supportedFileExtensions.includes(fileExtension.toLowerCase())) {
            // Open a modal to display the file
            const modalRef = this.modalService.open(ViewPodComponent, {
              size: 'lg',
              centered: false,
              backdrop: true,
              windowClass: 'viewPodModal',
              keyboard: true,
            });
            // Pass the file content to the modal component
            modalRef.componentInstance.pdfurl = response.downloadURL;
          } else {
            // Display an error message if the file type is not supported
            this.toast.error('We are unable to preview this POD file.');
          }
        } else {
          this.toast.error(response.message);
        }
      },
      (error) => {
        this.toast.error('Something Went Wrong!');
      }
    );
  }
}
