<!-- <div class="container shipment_cont">
    <div class="row">
        <div class="col-1" [ngClass]="sidebarToggle ? 'sidebarCollapse' :'sidebarNotCollapsed'">
            <app-leftsidebar></app-leftsidebar>
        </div> -->
        <div class="new-col shipment_right_sec">
            <div class="shipment_section">
                <div class="row m-0" style="opacity: 1;background-color: #f5f6fa;     position: sticky;
                        top: 66px;
                        z-index: 9;">
                    <div class="col-10 pageSubHeaderWithCss">
                        <!-- <div class="main-heading d-inline"> Orders
                        </div> -->
                        <span class="main-heading">Orders</span>
                        <div class="sub-headings">
                            <span class="topbtn" *ngFor="let item of headingArray"
                                [ngClass]="selectedTabId==item.id?'activeBtn':'inactive'"
                                (click)="allOrders(item.id)">{{item.value}}</span>
                            <!-- <span class="topbtn" style="margin: 0 30px 0 30px;"
                                        [ngClass]="transit ? 'activeBtn' : 'inactive'" (click)="inTransit()">In-Transit
                                        Orders</span>
                                    <span class="topbtn" [ngClass]="completed ? 'activeBtn' : 'inactive'"
                                        (click)="completedOrders()">Completed Orders</span>
                                    <span class="topbtn" style="margin-left: 25px;"
                                        [ngClass]="  scheduled? 'activeBtn' : 'inactive'" (click)="scheduledOrders()">Scheduled
                                        Orders</span> -->
                        </div>
                    </div>
                </div>
                <div class=" row shipment_listing" id="pageBodyCssS" style="height: calc(100vh - 243px);
                        overflow: scroll; border: 1px solid #c4cdd5">
                    <div class="col-12 shipment_listing_inner " style="height: 100%;">
                        <div class="row" style="position: sticky;
                                z-index: 9;
                                top: 0px;
                                background: white; min-width: 1863px">
                            <form [formGroup]="dateFilterForm">
                                <div class="col-12 applay_section ">
                                    <div class="row pl-0 mb-0 align-items-start">
                                        <div class="  pt-3 text-end filter" style="width: 50px; margin-top: 0.3px;">
                                            <select formControlName="filterKey" class="custom-select"
                                                (change)="filterByValue($event)" style="width:27px; height: 37px !important; border-radius: 0; border: 1px solid #CED0DA !important; padding: 2px 0 2px 0;  background-repeat: no-repeat;
                                          background-position: 4px 12px;
                                          background-size: 15px;
                                          text-align: center;
                                          padding-top: 2px;
                                          background-image:url(assets/img/shipment/fi-rr-filter.svg)">
                                                <option selected value="">All</option>
                                                <option value="CRS">Courier</option>
                                                <option value="LTL">LTL</option>
                                                <option value="VLTL">Volume LTL</option>
                                                <option value="FTL">Full Load</option>
                                            </select>
                                        </div>
        
                                        <div class=" pt-3 mr-2 ml-0 text-start serachbar-box"
                                            style="width: 350px;color: #354052;">
                                            <label> Search Orders</label>
                                            <input class="form-control searckInputField"
                                                (input)="updateSearchKey($event.target.value)"
                                                style="background-image: url('assets/img/quotes/search-icon.svg') !important; font-size: 13px !important;"
                                                type="text" [value]="searchKey" placeholder="Search Orders.." />
                                        </div>
        
                                        <div class=" ml-0 mr-3 pt-3 start-date-box"
                                            style="width: 125px; color: #354052; position: relative; padding-left: 0px !important;">
                                            <label>From Date</label>
                                            <!-- <div class="styleDateFlatPicker">
                                                        
                                                        <ng2-flatpickr #startDate placeholder="--"   [config]="startOptions"
                                                            formControlName="startDate"></ng2-flatpickr>
                                                        <img (click)="toggle('start')" style="width:10px !important"
                                                            src="assets/img/shipment/fi-rr-angle-small-down.svg">
                                                    </div> -->
                                            <img (click)="dtt.toggle()" style="width:16px !important; margin-right: 5px; position: absolute;
                                                    top: 50px;
                                                    left: 10px;" src="assets/img/shipment/fi-rr-calendar.svg">
                                            <input class="form-control  datepickerStyleInput" style="cursor:pointer; "
                                                placeholder="- -" name="fromDate" readonly="true" (click)="dtt.toggle()"
                                                [(ngModel)]="modelFromDate" formControlName="startDate" ngbDatepicker
                                                #dtt="ngbDatepicker" firstDayOfWeek="7" />
                                        </div>
        
                                        <div class=" mr-4 pt-3 end-date-box"
                                            style="width: 140px; color: #354052; position: relative; margin-left: 20px;">
                                            <label>To Date</label>
                                            <!-- <div class="styleDateFlatPicker">
                                                        
                                                        <ng2-flatpickr #endPicker placeholder="--" [config]="endOptions"
                                                            formControlName="endDate"  ></ng2-flatpickr>
                                                        <img (click)="toggle('end')" style="width:10px !important"
                                                            src="assets/img/shipment/fi-rr-angle-small-down.svg">
                                                    </div> -->
                                            <img (click)="toggle('end')" style="width:16px !important;margin-right: 5px;position: absolute;
                                                    top: 50px;
                                                    left: 19px;" src="assets/img/shipment/fi-rr-calendar.svg">
                                            <input class="form-control  datepickerStyleInput" style="cursor:pointer; "
                                                placeholder="- -" name="endDate" readonly="true" (click)="dt.toggle()"
                                                [(ngModel)]="modelEndDate" formControlName="endDate" ngbDatepicker
                                                #dt="ngbDatepicker" firstDayOfWeek="7" />
                                        </div>
                                        <div class="col-1 pt-3 text-center apply-btn-box" style="width: 6%;">
                                            <button type="button" style="padding: 0px 7px;" class="btn btn-outline-warning"
                                                (click)="resetFilter()">Reset</button>
                                        </div>
                                        <div class="col-1 pt-3 text-center apply-btn-box" style="width: 6%;">
                                            <button type="button" style="padding: 0px 7px;" class="btn btn-outline-warning"
                                                (click)="apply()">Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <!-- header start of shipment table -->
                            <div style="display: flex; min-width: 1850px; justify-content: space-between; background-color: #F5F6FA; padding: 8px 12px; border-bottom: 1px solid #c4cdd5;border-top: 1px solid #c4cdd5"
                                class="shipmentMainTable">
                                <div class="shipmentTableHeader" style="width: 150px;" (click)="sort('orderId')">
                                    <span>Order No. <img [class]="sortBy['orderId']"
                                            src="assets/img/quotes/down_arrow.svg" alt=""></span>
                                </div>
        
                                <div class="shipmentTableHeader" style="width: 150px;">
                                    <span>Probill No</span>
                                </div>
                                <div class="shipmentTableHeader" style="width: 100px;">
                                    <span>Carrier</span>
                                </div>
                                <div class="shipmentTableHeader" style="width: 150px;" (click)="sort('poNumber')">
                                    <span>PO No. <img [class]="sortBy['poNumber']" src="assets/img/quotes/down_arrow.svg"
                                            alt=""></span>
                                </div>
<!-- 
                                <div class="shipmentTableHeader" style="width: 150px;" (click)="sort('proNumber')">
                                    <span>Pro No. <img [class]="sortBy['proNumber']" src="assets/img/quotes/down_arrow.svg"
                                            alt=""></span>
                                </div>

                                <div class="shipmentTableHeader" style="width: 150px;" (click)="sort('bolNumber')">
                                    <span>BOL No. <img [class]="sortBy['bolNumber']" src="assets/img/quotes/down_arrow.svg"
                                            alt=""></span>
                                </div> -->


                                <div class="shipmentTableHeader" style="width: 150px;" (click)="sort('service')">
                                    <span>Service <img [class]="sortBy['service']" src="assets/img/quotes/down_arrow.svg"
                                            alt=""></span>
                                </div>
                                <div class="shipmentTableHeader" style="width: 150px;" (click)="sort('orderSource')">
                                    <span>Source <img [class]="sortBy['orderSource']" src="assets/img/quotes/down_arrow.svg"
                                            alt=""></span>
                                </div>
                                <div class="shipmentTableHeader" style="width: 150px;" (click)="sort('pickupDate')">
                                    <span>Pickup Date <img [class]="sortBy['pickupDate']" src="assets/img/quotes/down_arrow.svg"
                                            alt=""></span>
                                </div>
                                <div class="shipmentTableHeader" style="width: 150px;" (click)="sort('shipper')">
                                    <span>Shipper <img [class]="sortBy['shipper']" src="assets/img/quotes/down_arrow.svg"
                                            alt=""></span>
                                </div>
                                <div class="shipmentTableHeader" style="width: 150px;" (click)="sort('origin')">
                                    <span>Origin <img [class]="sortBy['origin']" src="assets/img/quotes/down_arrow.svg"
                                            alt=""></span>
                                </div>
                                <div class="shipmentTableHeader" style="width: 150px;" (click)="sort('consignee')">
                                    <span>Consignee <img [class]="sortBy['consignee']"
                                            src="assets/img/quotes/down_arrow.svg" alt=""></span>
                                </div>
                                <div class="shipmentTableHeader" style="width: 150px;" (click)="sort('destination')">
                                    <span>Destination <img [class]="sortBy['destination']"
                                            src="assets/img/quotes/down_arrow.svg" alt=""></span>
                                </div>
                                <!-- <div class="shipmentTableHeader" style="width: 150px; " (click)="sort('price')"> -->
                                <div class="shipmentTableHeader" style="width: 150px;">
                                    <span>Quotes 
                                        <!-- <img [class]="sortBy['price']" src="assets/img/quotes/down_arrow.svg" alt=""> -->
                                    </span>
                                </div>
                                <div class="shipmentTableHeader" style="width: 150px; ">
                                    <span>Status </span>
                                </div>
                                <div class="shipmentTableHeader" style="width: 100px; display: flex; justify-content: center;">
                                    <span>Action</span>
                                </div>
                            </div>
                            <!-- header end of shipment table -->
                        </div>
        
                        <div class="row shipment_history_table">
                            <div class="col-12" style="z-index: 2;">
                                <div *ngIf="isDataLoading!=''" style="font-size: 20px;
                                        font-weight: 500; height: calc(100vh - 402px);
            display: flex;
            justify-content: center;
            align-items: center;">
                                    {{isDataLoading}}
                                </div>
                                <ng-container *ngIf="isDataLoading==''">
                                    <div class="tableMainBody" style="min-width: 1850px; display: flex; justify-content: space-between; border-bottom: 1px solid #c4cdd5;
                                            align-items: center;
                                            padding: 5.5px 11px;" id="{{ship.orderId}}" *ngFor="let ship of shipments; let i = index ">
                                        <div class="tableBody" style="width: 150px;">
                                   <a style="cursor:pointer; color: #2877C6;"  (click)="handleOrderClick(ship, i)"
                                                >{{ship.customOrderId}}</a>
                                        </div>
                                        <div class="tableBody" style="width: 150px; word-break: break-all;">
                                            <span>{{ship.proBill?ship.proBill:"--"}}</span>
                                        </div>
                                        <div class="tableBody" style="width: 100px;">
                                            <div class="row">
                                                
                                                <div class="col-4"><img   *ngIf="ship.carrierName!=='Multiple'"style="height: 40px; width: 40px; margin-right: 5px;"
                                                        src="{{ship['quoteDetails'].carrierLogo ? ship['quoteDetails'].carrierLogo : 'assets/img/carrier/No-Carrier-Image.svg'}}"
                                                        alt="">
                                                        <span  *ngIf="ship.carrierName=='Multiple'" style="height: 40px !important;">Multiple</span>
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div class="tableBody" style="width: 150px; word-break: break-all;">
                                            <span>{{ship.poNumber?ship.poNumber:"--"}}</span>
                                        </div>

                                        <!-- <div class="tableBody" style="width: 170px; word-break: break-all;">
                                            <span>{{ship.proNumber?ship.proNumber:"--"}}</span>
                                        </div>

                                        <div class="tableBody" style="width: 170px; word-break: break-all;">
                                            <span>{{ship.bolNumber?ship.bolNumber:"--"}}</span>
                                        </div> -->


                                        <div class="tableBody" style="width: 150px;">
                                            <img *ngIf="ship.service!=='Multiple'" src="assets/img/quotes/shmt/{{ship['quoteDetails'].shipmentModeStyleCode}}.svg"
                                                width="15%"> {{ship.service?ship.service:"--"}}
                                        </div>
                                        <div class="tableBody" style="width: 150px;">
                                            {{ship.source =='NA'? "Manual":ship.source}}
                                        </div>
                                        <div class="tableBody" style="width: 150px;">
                                            <span>{{ship.pickupDate}}</span>
                                        </div>
                                        <div class="tableBody" style="width: 150px;">
                                            <span>{{ship.shipperCompany?ship.shipperCompany:"--"}}</span>
        
                                        </div>
                                        <div class="tableBody" style="width: 150px;">
                                            <span *ngIf="ship.shipperCompany=='Multiple'">Multiple</span>
                                            <span *ngIf="ship.shipperCompany!=='Multiple'">{{ship.shipperOrigin?ship.shipperOrigin:"--"}}</span>
        
                                        </div>
                                        <div class="tableBody" style="width: 150px; padding-left: 7.5px;">
                                            <span> {{ship.consigneeCompany?ship.consigneeCompany:'--'}}</span>
                                        </div>
                                        <div class="tableBody" style="width: 150px; padding-left: 7.5px;">
                                            <span *ngIf="ship.consigneeCompany=='Multiple'">Multiple</span>

                                            <span  *ngIf="ship.consigneeCompany!=='Multiple'">{{ship.consigneeOrigin?ship.consigneeOrigin:"--"}}</span>
        
        
                                        </div>
                                        <div *ngIf="ship.quoteType=='Multiple'" class="tableBody" style="width: 150px;  padding-left: 7.5px;" > Multiple</div>
                                        <div *ngIf="ship.quoteType!=='Multiple'" class="tableBody" style="width: 150px;  padding-left: 7.5px;">
                                            $ {{ ship.price?ship.price:0.00}}&nbsp; <small>{{ship['quoteDetails'].currency ?
                                                ship['quoteDetails'].currency : ''}} </small>
                                            <img src="assets/img/quotes/down_icon.svg"
                                                style="cursor: pointer; margin-left: 5px;" placement="bottom"
                                                popoverClass="popover_shipment_quote" [ngbPopover]="popContent"
                                                (click)="popOverIndex(i)" />
                                        </div>
                                        <div  style="width: 150px; padding-left: 7.5px;" class="tableBody" *ngIf="ship.quoteType=='Multiple'" > Multiple</div>
        
                                        <div class="tableBody" *ngIf="ship.quoteType!=='Multiple'" style="width: 150px; padding-left: 7.5px;">
                                            <div
                                                [class]="ship && ship?.status=='FAILED' ? 'badge badge-danger' : ship?.status=='DELIVERED' ? 'badge badge-success' : ship?.status=='INTRANSIT' ? 'badge badge-warning' :  ship?.status=='SCHED' ? 'badge badge-primary': 'badge badge-secondary'">
                                                {{ship.status==='PICKEDUP'?'PICKED UP':ship.status==='NO_PICKUP'?'NO
                                                PICK UP':ship.status==='NO_DELIVERED'?'NO DELIVERED':ship.status}}
                                            </div>
                                            <span class="ml-2" *ngIf="ship?.status =='DELIVERED' ">
                                                <img src="assets/img/quotes/down_icon.svg" style="cursor: pointer;"
                                                    (click)="showPOD(ship['quoteDetails']['quoteId'],ship['orderId'],ship?.status)"
                                                    placement="bottom" popoverClass="popover_shipment_status"
                                                    [ngbPopover]="popContentDelivered" (click)="popOverIndex(i)" />
        
                                            </span>
                                            <span class="ml-2" *ngIf=" ship?.status =='INTRANSIT'">
                                                <img src="assets/img/quotes/down_icon.svg" style="cursor: pointer;"
                                                    (click)="ViewDetailsON(ship['quoteDetails']['quoteId'],ship['orderId'],ship?.status)"
                                                    placement="bottom" popoverClass="popover_shipment_status"
                                                    [ngbPopover]="popContentIntransit" (click)="popOverIndex(i)" />
        
                                            </span>
                                        </div>
                                        <div class="tableBody" style="width: 100px; display: flex; justify-content: center;">
                                            <!-- <img src="assets/img/quotes/hor_3_dots.png" class="dropdown-toggle "
                                                data-toggle="dropdown"> -->
                                                <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" multiple>
                                                    <a class="dropdown-item"
                                                        (click)="viewQuote(ship['source'],ship['quoteDetails']['quoteId'],ship['customOrderId'],ship['orderId'],ship?.status)">View
                                                        Order</a>
                                                    <a class="dropdown-item" *ngIf="ship.status!='FAILED'" (click)="saveToFavorite(ship.orderId)">Save to Favorites</a>
                                                    <a class="dropdown-item" *ngIf="ship.source=='Quoted'" (click)="shipAgain(ship['quoteDetails']['quoteId'],ship['source'], ship['orderId'])">Copy Order</a>
                                                    <a class="dropdown-item" (click)="cancelOrder(ship,i)"
                                                        *ngIf="ship.status!='FAILED' && (ship.status==='SCHED' || ship.status==='!INTRANSIT')">
                                                        Cancel Order
                                                    </a>   
                                                    <a class="dropdown-item" *ngIf="ship?.status!='INTRANSIT' && ship?.status!='DELIVERED' && ship.status!='CANCELLED' && ship.status!='FAILED' && ship.source=='Quoted'"
                                                        (click)="getOrderMetaDataById(ship['quoteDetails']['quoteId'],ship['customOrderId'],ship['orderId'],ship?.status,ship['consigneeName'],ship['quoteDetails']['customerId'],ship.quoteDetails.carrierName,ship['quoteDetails'],ship.quoteDetails.carrierLogo, ship.source)">
                                                        Edit Order 
                                                    </a>
                                                    <a class="dropdown-item"
                                                        *ngIf="ship?.status!='INTRANSIT' && ship.status!='CANCELLED' && ship.status!='FAILED' && ship.status!='SCHED'"
                                                        (click)="viewPod(ship)">
                                                        View POD
                                                    </a>
                                                </div> -->
                                                <div style="width: 79px; padding: 0; text-align: center;margin-left: 20px;" class="action_td">
                                                    <img *ngIf="ship['quoteType']!=='Multiple'" src="assets/img/quotes/hor_3_dots.png" class="dropdown-toggle" data-toggle="dropdown">
                                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" multiple>
                                                    <a class="dropdown-item"
                                                        (click)="viewQuote(ship['source'],ship['quoteDetails']['quoteId'],ship['customOrderId'],ship['orderId'],ship?.status)">View
                                                        Order</a>
                                                    <div class="dropdown-divider"></div>
        
                                                    <a class="dropdown-item" *ngIf="ship.status!='FAILED'" (click)="saveToFavorite(ship.orderId)">Save to Favorites</a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'&& (ship.source=='Online'|| ship.status==='!INTRANSIT'||ship.status==='SCHED')"></div>
        
                                                    <a class="dropdown-item" *ngIf="ship.source=='Online'" (click)="shipAgain(ship['quoteDetails']['quoteId'],ship['source'], ship['orderId'])">Copy Order</a>
                                                    <div class="dropdown-divider" *ngIf="ship.source=='Online' && ship.status!='FAILED'&& ship.status!='CANCELLED'&& ship.status!='INTRANSIT'"></div>
        
                                                    <a class="dropdown-item" (click)="cancelOrder(ship,i)"
                                                        *ngIf="ship.status!='FAILED' && (ship.status==='SCHED' || ship.status==='!INTRANSIT')">
                                                        Cancel Order
                                                    </a>   
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED' && ship.source=='Online'&&(ship.status==='SCHED' || ship.status==='!INTRANSIT')"></div>
        
                                                    <a class="dropdown-item" *ngIf="ship?.status!='INTRANSIT' && ship?.status!='DELIVERED' && ship.status!='CANCELLED' && ship.status!='FAILED' && ship.source=='Online'"
                                                        (click)="getOrderMetaDataById(ship['quoteDetails']['quoteId'],ship['customOrderId'],ship['orderId'],ship?.status,ship['consigneeName'],ship['quoteDetails']['customerId'],ship.quoteDetails.carrierName,ship['quoteDetails'],ship.quoteDetails.carrierLogo, ship.source)">
                                                        Edit Order 
                                                    </a>
                                                    <div class="dropdown-divider" *ngIf="ship?.status!='INTRANSIT' && ship?.status!='DELIVERED' && ship.status!='CANCELLED' && ship.status!='FAILED' && ship.source=='Online'&& ship.status!='SCHED'"></div>
        
                                                    <div class="dropdown-divider"  *ngIf="ship?.status!='INTRANSIT' && ship.status!='CANCELLED' && ship.status!='FAILED' && ship.status!='SCHED' && ship.source!=='Online'"></div>
                                                    <a class="dropdown-item"
                                                        *ngIf="ship?.status!='INTRANSIT' && ship.status!='CANCELLED' && ship.status!='FAILED' && ship.status!='SCHED'"
                                                        (click)="viewPod(ship)">
                                                        View POD
                                                    </a>

                                                    
                                                    


                                                    </div>
                                                  </div>
        
        
                                                <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" multiple>
                                                    <a class="dropdown-item"
                                                        (click)="viewQuote(ship['source'],ship['quoteDetails']['quoteId'],ship['customOrderId'],ship['orderId'],ship?.status)">View Order</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" (click)="getQuote(i)">Ship Again</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" *ngIf="ship.status!='FAILED'"
                                                        (click)="saveToFavorite(ship.orderId)">Save to Favorite</a>
                                                    <div class="dropdown-divider"
                                                        *ngIf="ship.status!='FAILED' && (ship.status==='SCHED' || ship.status==='INTRANSIT')">
                                                    </div>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item">Copy Order</a>
                                                    
                                                    <a class="dropdown-item" (click)="cancelOrder(ship,i)"
                                                        *ngIf="ship.status!='FAILED' && (ship.status==='SCHED' || ship.status==='INTRANSIT')">Cancel
                                                        Order</a>
                                                        <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item" *ngIf="ship.status!='FAILED'">Carrier POD Request </a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item" *ngIf="ship.status!='FAILED'">Return to In Transit</a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">View
                                                        POD</a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">View
                                                        Invoice</a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">Make
                                                        Payment</a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">File a
                                                        Cliam</a>
                                                </div> -->
                                        </div>
                                    </div>
                                    <div style="font-size: 14px; font-weight: 500; padding: 10px; display: flex; justify-content: center; align-items: center;"
                                        *ngIf="isLoading">
                                        Loading...
                                    </div>
                                </ng-container>
        
        
        
                            </div>
                        </div>
        
                    </div>
                </div>
        
        
        
        
        
        
                <!-- <div class="col-12 pt-3 pl-4">
                          <p class="copyright d-inline">© {{cYear}} ShipCarte. All Rights Reserved.</p>
                          <span class="ml-1 mt-1 mb-5 d-inline">
                                  <a href="https://www.linkedin.com/company/shipcarte-inc/" target="_blank">
                                      <img src="assets/img/quotes/linked-in.svg" width="13px" class="m-1 text-center">
                                  </a>
                                  <a href="https://www.instagram.com/shipcarte/?hl=en%C2%A0" target="_blank">
                                      <img src="assets/img/quotes/instagram.svg" width="13px" class="m-1 text-center">
                                  </a>
                                  <a href="https://www.youtube.com/channel/UC28WEGzSkupdg6T7xd7yrTw" target="_blank">
                                      <img src="assets/img/quotes/youtube.svg" width="17px" class="m-1 text-center">
                                  </a>
                                  <a href="https://twitter.com/shipcarte?lang=en%C2%A0" target="_blank">
                                      <img src="assets/img/quotes/twitter.svg" width="17px" class="m-1 text-center">
                                  </a>
                                  <a href="https://www.facebook.com/ShipCarte/" target="_blank">
                                      <img src="assets/img/quotes/facebook.svg" width="8px" class="m-1 text-center">
                                  </a>
                              </span>
                      </div> -->
            </div>
        </div>
        <!-- </div>
        </div> -->
        
        <app-spinner></app-spinner>
        <!-- ################################ Add Fevrate ################################################## -->
        <ng-template #content let-c="close" let-d="dismiss">
            <form [formGroup]="addFevForm">
                <div class="modal-header">
                    <h3 style="font-weight: 500;font-size: 18px;padding: 10px 0px;font-family: 'Poppins' !important"> Saving to
                        Favorites </h3>
                    <img class="crossImageStyle" style="margin-top: 10px;" (click)="d('Cross click')"
                        src="assets/img/common/fi-rr-cross-small.svg">
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-12">
                            <div class="form-group p-0  m-0">
                                <label class="control-label formLabel"
                                    style="font: size 16px !important;; color: #354052 !important;; " for="addFev">Favorite
                                    order name</label>
                                <div class="customerInput">
                                    <input id="addFev" type="text" class="form-control" formControlName="addFev"
                                        (input)="setValue($event.target.value)" placeholder="Enter Favorite order name">
                                    <!-- <span style="color:#FF0000;font-size: 12px;" *ngIf="formErrorsContact?.fnameContact">
                                    gdfgdfggfdf 
                                    </span> -->
                                    <!-- <span line.get('Quantity').invalid && (line.get('Quantity').dirty   || line.get('Quantity').touched) ></span> -->
          
                                    <div *ngIf="  addFevForm.controls['addFev'].invalid && (addFevForm.controls['addFev'].dirty   || addFevForm.controls['addFev'].touched)"
                                        style="color: red;" class="error">
          
                                        <div class="error" style="color: red !important;"
                                            *ngIf="addFevForm.controls['addFev'].errors.required">
          
                                            Field is required.
          
          
                                        </div>
          
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="margin-left: 47%; display:flex !important; justify-content: end;">
                    <button type="button" style=" color:black;  width:50px; margin-right:22px; font-size: 14px !important; "
                        class="btn btn-cancel cancleBtn " (click)="d('Cancel click')">Cancel</button>
                    <button type="button"
                        style="padding: 0px 7px; width: 86px; height: 36px; background-color: #FF9A00; color: white; "
                        (click)="  c('Save click')" class="btn btn-color">Add</button>
                </div>
            </form>
          </ng-template>
          
          <!-- ###############################  confirm fev ################################## -->
          <ng-template #confirmWarn let-modal>
            <div>
                <div class="row" style="margin-top: 30px; ">
                    <div class="col-12 text-center">
                        <!-- <h5 style="margin-top: 5px !important; font-size: 18px !important; font-weight: 600 !important; font-family: poppins,sans-serif !important;">BOOK image</h5> -->
                        <img style="padding-top: 10px;height: 70px;" src="assets/img/Warning.svg" alt="">
                    </div>
          
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <p class="mb-0"
                            style="font-size: 16px; font-weight: 500; font-family: poppins; color:#354052;padding: 0 40px 0 40px;">
                            Confirm you want to save it to your favorites?
                        </p>
          
                    </div>
                </div>
                <div class="row mt-5 ml-0 mr-0"> 
                    <div class="modal-footer" style="margin-left: 50%; display:flex !important; justify-content: end;">
                        <button type="button" style=" color:black; border: none; width:50px; margin-right: 14px; "
                            class="  saveToFavCancelButton" (click)="modal.dismiss('Cancel click')">Cancel</button>
                        <button type="button"
                            style="padding: 0px 7px; width: 86px; height: 36px; background-color: #FF9A00; color: white; margin-left:10px ;"
                            (click)="modal.close('Save click')" class="btn btn-color">Add</button>
                    </div>    
                </div>
            </div>
          </ng-template>
        <ng-template #popContent style="color: #354052;">
            <div class="popupHeaderStyle mr-1 ml-1 mt-1 mb-3">
                <div
                    style="font-size: 18px; font-weight: 500; font-family: poppins,sans-serif; color: #354052;margin-left: 10px;">
                    Quote Summary</div>
                <!-- <button> -->
                <img style="cursor: pointer;margin-right: 12px;" src="assets/img/shipment/fi-rr-cross-small.svg">
                <!-- </button> -->
            </div>
        
            <div class="row mr-1 ml-1  mb-3">
                <div class="col-6"><img
                        src="{{popOverDataPrice.carrierLogo ? popOverDataPrice.carrierLogo : 'assets/img/payment/Courier.svg'}}"
                        width="60px" class="m-1"></div>
                <div class="col-6 text-right">
                    <div
                        style="font-size: 14px; font-weight: 500; font-family: poppins,sans-serif; color: #354052; margin-bottom: 7px;">
                        {{popOverDataPrice.shpmtModeName}} <img
                            src="assets/img/quotes/shmt/{{popOverDataPrice.shipmentModeStyleCode}}.svg" width="30px"
                            class="m-1"></div>
                    <div style="font-size: 14px; font-weight: 500; font-family: poppins,sans-serif; color: #354052;">Weight:
                        {{popOverDataPrice && popOverDataPrice?.weight | number : '1.2-2'}} Lbs</div>
                </div>
        
            </div>
            <div class="grey-devider"></div>
            <div class="row  mr-1 ml-1  mb-3"
                style="font-size: 14px; font-weight: 500; font-family: poppins,sans-serif; color: #354052;">
                <div class="col-6">
                    Quote No.
                </div>
                <div class="col-6 blue-text text-right">{{popOverDataPrice.customId}}</div>
            </div>
        
            <div class="row  mr-1 ml-1  mb-3 "
                style="font-size: 14px; font-weight: 500; font-family: poppins,sans-serif; color: #354052;"
                *ngFor="let charge of popOverDataPrice.price_breakUp; let i = index">
                <div class="col-6">
                    {{charge[0]}}
        
                </div>
                <div class="col-6 text-right"> $ {{charge[1]}}</div>
            </div>
            <div class="row mr-1 ml-1  mb-3"
                style="font-size: 14px; font-weight: 500; font-family: poppins,sans-serif; color: #354052;">
                <div class="col-6">
                    Discount
                </div>
                <div class="col-6 text-right">$ {{popOverDataPrice.discount ? popOverDataPrice.discount : '00.00'}}</div>
            </div>
            <div class="row mr-1 ml-1  mb-3"
                style="font-size: 14px; font-weight: 500; font-family: poppins,sans-serif; color: #354052;">
                <div class="col-6">
                    Shipcarte Insurance
                </div>
                <div class="col-6 text-right">$ {{popOverDataPrice.scInsurance ? popOverDataPrice.scInsurance : '00.00'}}</div>
            </div>
        </ng-template>
        <ng-template #popContentDelivered style="color: #354052;">
            <div class="popupHeaderStyle mr-1 ml-1 mt-2 mb-2">
                <div style="font-size: 18px; font-weight: 500; font-family: poppins,sans-serif;">Status on Delivery</div>
                <!-- <button> -->
                <img style="cursor: pointer;" src="assets/img/shipment/fi-rr-cross-small.svg">
                <!-- </button> -->
            </div> <br>
            <div class="badge badge-pill badge-success mb-3" style="margin-left: 15px;">DELIVERED</div>
            <p class="d-inline blue-text pl-2"><a
                    style="text-decoration: none; cursor: pointer; font-size: 14px; color: #1991EB;" (click)="viewPOD()">View
                    POD</a> </p>
            <div class="row mr-1 ml-1  mb-2"
                style="font-size: 14px; font-weight: 500; font-family: poppins,sans-serif; color: #354052;">
        
                <div class="col-6">Received By</div>
                <div class="col-6 text-right">{{trackingData['receivedBy'] ? trackingData['receivedBy'] :'--'}}</div>
            </div>
            <div class="grey-devider"></div>
            <div class="row mr-1 ml-1  mb-3"
                style="font-size: 14px; font-weight: 500; font-family: poppins,sans-serif; color: #354052;">
        
                <div class="col-6">Date</div>
                <div class="col-6 text-right">{{ trackingData?.receivedDate ? (trackingData?.receivedDate | date:'MM-dd-yyyy') : '--' }}
        </div>
            </div>
            <div class="grey-devider"></div>
            <div class="row mr-1 ml-1  mb-3"
                style="font-size: 14px; font-weight: 500; font-family: poppins,sans-serif; color: #354052;">
        
                <div class="col-6">Time</div>
                <div class="col-6 text-right">{{trackingData['receivedTime'] ? trackingData['receivedTime'] :'--/--/--'}}</div>
            </div>
        </ng-template>
        <ng-template #popContentIntransit style="color: #354052;">
            <div class="popupHeaderStyle mr-1 ml-1 mt-2 mb-2">
                <div style="font-size: 18px; font-weight: 500; font-family: poppins,sans-serif; color: #354052;">Status on
                    Delivery</div>
                <!-- <button> -->
                <img style="cursor: pointer;" src="assets/img/shipment/fi-rr-cross-small.svg">
                <!-- </button> -->
            </div> <br>
            <div class="badge badge-pill badge-warning mb-3" style="margin-left: 15px;">In-TRANSIT</div>
        
            <div class="mr-1 ml-1  mb-3 row"
                style="font-size: 16px; font-weight: 500; font-family: poppins,sans-serif; color: #354052;">
        
                <div class="col-6">Original Delivery Date</div>
                <div class="col-6 text-right">
                    {{ trackingData['originalDeliveryDate'] === '--' ? '--' : (trackingData['originalDeliveryDate'] === 'NA' ? '--' : (trackingData['originalDeliveryDate'] | date:'MM-dd-yyyy')) }}
        </div>
            </div>
            <div class="grey-devider"></div>
            <div class="mr-1 ml-1  mb-3 row">
        
                <div class="history-tl-container">
                    <ul class="tl">
                        <li class="tl-item">
                            <div class="item-title"><span
                                    style="font-size: 16px; font-weight: 500; font-family: poppins,sans-serif; color: #354052; margin-right: 10px;"
                                    class="text-dark">{{shipperZip}}</span>
                                <p class="d-inline h5 fontPopup"> Origin Zip</p>
                            </div>
                        </li>
                        <li class="tl-item">
                            <div class="item-title"><span
                                    style="font-size: 16px; font-weight: 500; font-family: poppins,sans-serif; color: #354052;margin-right: 10px;"
                                    class="text-dark">{{consigneeZip}}</span>
                                <p class="d-inline h5 fontPopup" > Destination Zip</p>
                            </div>
                        </li>
                    </ul>
                    <div class=" row  mb-3">
                        <div class="col-7">
                            <div style="font-size: 16px; font-weight: 500; font-family: poppins,sans-serif; color: #354052;">
                                Current Location</div>
                            <div class="grey-text fontPopup" >{{trackingData['currentLocation'] ?
                                trackingData['currentLocation'] : '--'}}</div>
                        </div>
                        <div class="col-5 text-end blue-text"><a (click)="viewDetails()"
                                style="text-decoration: none; cursor: pointer; font-size: 14px">&#43; View Details</a> </div>
                    </div>
                </div>
            </div>
            <div class="grey-devider"></div>
            <div class=" row mr-1 ml-1  mb-3"
                style="font-size: 16px; font-weight: 500; font-family: poppins,sans-serif; color: #354052;">
                <div class="col-7">Expected Delivery Date</div>
                <div class="col-5 text-right">{{ trackingData['estimatedDeliveryDate'] === '--' ? '--' : (trackingData['estimatedDeliveryDate'] === 'NA' ? '--' : (trackingData['estimatedDeliveryDate'] | date:'MM-dd-yyyy')) }}
        
        </div>
            </div>
        </ng-template>
        