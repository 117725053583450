<div class="row mb-5">
    <div id="DownloadPdfData" class="col-12 p-0 card " style="border: none !important;">
        <div style="display: flex; justify-content: end; margin-bottom: 20px; ">
            <img (click)="closeModal()" style="width: 15px;" src="assets/img/shipment/fi-rr-cross-small.svg" alt="">
        </div>
        <div>
            <!DOCTYPE html>
            <html>

            <head>
                <style type="text/css">
                    .blo_connaissement_right1 {
                        font-family: "Poppins", sans-serif;
                        margin-top: 20px;
                        text-align: center;
                    }

                    .blo_connaissement_right1 .blo_left1 {
                        font-size: 11px;
                    }

                    .blo_connaissement_right1 .blo_right1 {
                        font-size: 11px;
                    }

                    .order-details_section1 {
                        margin: 0 0 6px 0 !important;
                        font-family: "Poppins", sans-serif;
                    }

                    .order-details1 {
                        font-size: 12px !important;
                        font-weight: 600;
                        font-family: "Poppins", sans-serif;
                    }

                    .table_start1 {
                        width: 100%;
                        float: left;
                        font-family: "Poppins", sans-serif;
                    }

                    .table-heading1 {
                        width: 100%;
                        float: left;
                        padding: 0;
                        font-size: 15px;
                        background-color: #E2E7EE77;
                        border-top: 0.5px solid #D4D4D4;
                        border-left: 0.5px solid #D4D4D4;
                        border-right: 0.5px solid #D4D4D4;
                    }

                    .shipper-heading1 {
                        width: 50%;
                        float: left;
                        font-weight: 600;
                    }

                    .consignee-heading1 {
                        width: 50%;
                        float: left;
                        font-weight: 600;
                    }

                    .table-data1 {
                        width: 100%;
                        float: left;
                        border: 1px solid #D4D4D4;
                        padding: 0;
                        font-size: 10px;
                        line-height: 1.2;
                        font-weight: 400;
                    }

                    .shipper_box1 {
                        width: 50%;
                        float: left;
                        padding: 3px;
                    }

                    .shipper_box1 div {
                        padding: 1px 0 1px 0;
                    }

                    .consignee_box1 {
                        width: 50%;
                        float: left;
                        padding: 3px;
                    }

                    .consignee_box1 div {
                        padding: 1px 0 1px 0;
                    }

                    .bill_freight_strip1 {
                        width: 100%;
                        float: left;
                        background-color: #E2E7EE77;
                        border: 1px solid #D4D4D4;
                        padding: 0;
                        font-size: 10px;
                        text-align: center;
                    }

                    .shipping_charges_strip1 {
                        width: 100%;
                        float: left;
                        border: 1px solid #D4D4D4;
                        padding: 0;
                        font-size: 12px;
                        text-align: center;
                        text-transform: uppercase;
                    }

                    .received_text_section1 {
                        width: 100%;
                        float: left;
                        border: 1px solid #D4D4D4;
                        padding-left: 3px;
                        font-size: 8px;
                        font-weight: 500;
                        font-family: "poppins", sans-serif;
                    }

                    .pro_details_table1 {
                        width: 100%;
                        float: left;
                        font-family: "poppins", sans-serif;
                    }

                    .pro_details_table1 table th {
                        font-weight: bold;
                        font-size: 14px;
                        font-family: "poppins", sans-serif;
                        text-align: left;
                        float: left;
                        padding: 2px !important;
                        margin: 0 2px 0 0 !important;
                    }

                    .pro_details_table1 table td {
                        font-size: 10px;
                        font-weight: 400;
                        width: 100%;
                        font-family: "poppins", sans-serif;
                        float: left;
                        padding: 2px !important;

                    }

                    .pro_details_table1 tbody {
                        width: 100%;
                        float: left;
                    }

                    .yellow-header1 {
                        background: #f7c765;
                        width: 100%;
                        float: left;
                        font-size: 14px;
                        font-weight: 600;
                        padding: 0 !important;
                    }

                    .shipment_size_box1 {
                        width: 100%;
                        float: left;
                        font-family: "poppins", sans-serif;
                        border: 1px solid #D4D4D4;
                    }

                    .shipment_box_11 {
                        width: 33%;
                        float: left;
                    }

                    .orange_heading {
                        color: #FF8D00;
                        font-size: 7px;
                        font-weight: 600;
                    }

                    .Declared_box1 {
                        width: 100%;
                        float: left;
                        padding: 0;
                        border: 1px solid #D4D4D4;
                    }

                    .consignor_signature_box1 {
                        width: 100%;
                        float: left;
                        padding: 3px;
                        font-size: 9px;
                        font-family: "poppins", sans-serif;
                        font-weight: 400;
                        border: 1px solid #D4D4D4;
                    }

                    .freight_collect_shipments_box1 {
                        width: 100%;
                        float: left;
                        background-color: #E2E7EE77;
                    }

                    .signature_line1 {
                        width: 29%;
                        height: 1px;
                        float: left;
                        background: #868585;
                        margin: 10px 0 0 0;
                    }

                    .page_heading {
                        font-size: 16px;
                        font-family: "poppins", sans-serif;
                    }

                    .second-sec h5 {
                        font-size: 6px !important;
                    }

                    .break-page {
                        page-break-before: always;
                        /* Start each new content section on a new page */
                    }

                    .second-sec p {
                        font-size: 10px !important;
                        line-height: 12px !important;
                    }

                    .page-break {
                        page-break-after: always;
                        page-break-inside: avoid;
                        clear: both;
                    }

                    .page-break-before {
                        page-break-before: always;
                        page-break-inside: avoid;
                        clear: both;
                    }
                </style>

            </head>

            <body>
                <!-- [ngClass]="printBOL ? 'printable' : '' " -->
                <div style="background: #fff;margin: auto;width: 100%; ;
        overflow: hidden;">
                    <div id="content" #content class="bol-page_section"
                        style="background: #fff;padding: 3px;height: auto;border: 1px solid #D4D4D4; border-radius: 6px;float: left;">
                        <div>
                            <div class=" row blo_connaissement_section  mb-0">
                                <div class=" col-4 blo_connaissement_left1 text-start" style="display: flex;">
                                    <img style="width: 220px;" src="assets/img/shipcarte-logo-350x130.png" alt="" />

                                </div>
                                <div class=" col-8 blo_connaissement_right1 text-end"
                                    style="margin-top: 0px !important; padding-top: 16px; display: flex; justify-content: end;">
                                    <div class="row" style="justify-content: end; margin-bottom: 0px !important;">
                                        <div class=" blo_left1"
                                            style="line-height: 1.2; margin-right: 16px; margin-top: 5px;">
                                            <div style="color:#FF8D00;font-weight: 600;">ATTENTION CARRIER:
                                            </div>
                                            <div>SHIPCARTE ORDER NO. MUST</div>
                                            <div>APPEAR ON THE FREIGHT BILL</div>
                                        </div>
                                        <!-- <div class=" col-6 blo_right1">
                                        <div style="color:#FF8D00;font-weight: 600;">ATTENTION PORTEUR:
                                        </div>
                                        <div>SHIPCARTE NUMERO DESTINATAIRE</div>
                                        <div>COMMANDE NO. DOIT APPARAIT</div>
                                        <div>SUR LE PROJET DE LOI DE FRET</div>
                                    </div> -->
                                    </div>
                                    <div>
                                        <h3 style="font-size: 20px !important;margin-bottom: 0;">BILL OF LADING
                                        </h3>
                                        <div>{{BOLd?.bolNumber}}</div>
                                    </div>

                                </div>
                            </div>
                            <div class="row order-details_section1">
                                <div class="col-2 order-details1 text-start">
                                    <div>ORDER NO.</div>
                                    <!-- <div>NUMERO DE COMMANDE</div> -->
                                    <div style="color:#FF8D00; font-size: 12px !important; font-weight: 400;">
                                        {{BOLd?.orderNumber}}</div>
                                </div>
                                <div class="col-3 p-0 order-details1 text-start">
                                    <div>SHIP DATE</div>
                                    <!-- <div>D'EXPEDITION</div> -->
                                    <div style="font-size: 12px !important; font-weight:400">
                                        {{ BOLd?.shipDate ? (BOLd?.shipDate | date:'MM-dd-yyyy') : '--' }}
                                    </div>
                                </div>
                                <div class="col-5 p-0 order-details1 text-start">
                                    <div class="d-flex">
                                        <span style="width: 49%;">CUSTOMER REF NO.</span>
                                        <span style="width: 2%;">/</span>
                                        <span style="width: 49%;">REFERENCE CLIENT NO.</span>
                                    </div>
                                    <div class="d-flex" style="font-size: 12px !important; font-weight:400">
                                        <span style="width: 49%; word-break: break-all;">{{invoiceData?invoiceData.poNumber?invoiceData.poNumber:'--':'--'}}</span>
                                        <span style="width: 2%;">/</span>
                                        <span style="width: 49%; word-break: break-all;">{{BOLd?BOLd?.customerNumber?BOLd?.customerNumber:'--':'--'}}</span>
                                    </div>
                                </div>
                                <div class="col-2 order-details1 text-start">
                                    <div>ARRIVAL DATE</div>
                                    <!-- <div>DATE D'ARRIVEE</div> -->
                                    <div style="font-size: 12px !important; font-weight:400">--</div>
                                </div>
                            </div>
                            <div class="table_start1">
                                <div class="table-heading1">
                                    <div class="shipper-heading1">SHIPPER </div>
                                    <div class="consignee-heading1">CONSIGNEE </div>
                                </div>
                                <div class="table-data1">
                                    <div class="shipper_box1 mr-1"
                                        style="border-right: 1px solid #D4D4D4; width: 49%;">
                                        <div>{{BOLd?.shippercompany | uppercase}}</div>
                                        <!-- <div>{{BOLd?.shipperfullAddr | uppercase}}</div> -->
                                        <div>{{BOLd?.shipperaddrLine1 | uppercase}}</div>
                                        <!-- <div>{{BOLd?.shipperstate | uppercase}}</div> -->
                                        <div>{{BOLd?.shippercity | uppercase}} {{BOLd?.shipperstate | uppercase}} {{BOLd?.shipperzip |
                                            uppercase}}, {{BOLd?.shipperCountry | uppercase}}</div>
                                             <div class="row pb-0" style="margin: unset !important;">
                                                <div class="col-6 d-flex pb-0">
                                                    <div><b>CONTACT:</b></div> 
                                                    <div class="ml-1" style="word-break: break-all;"> {{resultData ?
                                                        resultData['shipperAddress']['firstName'] : '' | uppercase}}
                                                        {{resultData ? resultData['shipperAddress']['lastName'] : '' |
                                                        uppercase}}</div>             
                                                </div>
                                                <div class="col-6 pb-0">
                                                    <div class="col-12 d-flex pb-0">
                                                        <div> <b>TEL:</b></div> 
                                                        <div class="ml-1" style="word-break: break-all;">  {{resultData ? resultData['shipperAddress']['phoneNumber'] : ''}}</div>             
                                                    </div>
                                                   
                                               
                                                </div>
                                                </div>
                                        <!-- <div><b>CONTACT:</b> {{resultData ?
                                            resultData['shipperAddress']['firstName'] : '' | uppercase}}
                                            {{resultData ? resultData['shipperAddress']['lastName'] : '' |
                                            uppercase}}</div>
                                        <div style="float:left;margin:0px 55px 0px 0px;"><b>TEL:</b>
                                            {{resultData ? resultData['shipperAddress']['phoneNumber'] : ''
                                            }}</div> -->
                                            <div><b>BUSINESS TYPE:</b> {{resultData?.shipperAddress?.addrTypeId || '--'  | uppercase}}</div>
                                        <div><b>PICK UP DATE:</b> {{ BOLd?.pickupDate ? (BOLd?.pickupDate | date:'MM-dd-yyyy') : '--' }}</div>
                                        <div><b>HOURS OF PICK UP:</b> {{ resultData?.pickupFromTime ? resultData.pickupFromTime: '--' }} to {{ resultData?.pickupToTime ?
                                            resultData.pickupToTime: '--' }}</div>
                                        <div><b>P/U INSTRUCTIONS:</b> {{ resultData?.pickupInstruction ? resultData.pickupInstruction : '--' }}</div>
                                        <div>
                                            <b>SHIPPER:</b>
                                            <ng-container
                                                *ngIf="resultData?.shipperAddress.extraServices && resultData?.shipperAddress.extraServices.length > 0; else noServices">
                                                <ng-container
                                                    *ngFor="let extraService of resultData?.shipperAddress.extraServices; let last = last">
                                                    {{ extraService }}{{ !last ? ',' : '' }}
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #noServices>--</ng-template>
                                        </div>
                                    </div>
                                    <div class="consignee_box1">
                                        <div>{{BOLd?.consigneecompany | uppercase}}</div>
                                        <!-- <div>{{BOLd?.consigneefullAddr | uppercase}}</div> -->
                                        <div>{{BOLd?.consigneeaddrLine1 | uppercase}}</div>
                                        <!-- <div>{{BOLd?.consigneestate | uppercase}}</div> -->
                                        <div>{{BOLd?.consigneecity | uppercase}} {{BOLd?.consigneestate | uppercase}} {{BOLd?.consigneezip |
                                            uppercase}}, {{BOLd?.consigneeCountry | uppercase}}</div>
                                         <div class="row pb-0" style="margin: unset !important;">
                                            <div class="col-6 d-flex pb-0">
                                                <div><b>CONTACT:</b></div> 
                                                <div class="ml-1" style="word-break: break-all;"> {{resultData ?
                                                    resultData['consigneeAddress']['firstName'] : '' | uppercase}}
                                                    {{resultData ? resultData['consigneeAddress']['lastName'] : '' |
                                                    uppercase}}</div>             
                                            </div>
                                            <div class="col-6 pb-0">
                                                <div class="col-12 d-flex pb-0">
                                                    <div> <b>TEL:</b></div> 
                                                    <div class="ml-1" style="word-break: break-all;">  {{resultData ? resultData['consigneeAddress']['phoneNumber'] :
                                            ''}}</div>             
                                                </div>
                                               
                                           
                                            </div>
                                            </div>
                                        <!-- <div><b>CONTACT:</b> {{resultData ?
                                            resultData['consigneeAddress']['firstName'] : '' | uppercase}}
                                            {{resultData ? resultData['consigneeAddress']['lastName'] : '' |
                                            uppercase}}</div>
                                        <div style="float:left;margin:0px 55px 0px 0px;"><b>TEL:</b>
                                            {{resultData ? resultData['consigneeAddress']['phoneNumber'] :
                                            ''}}</div> -->
                                        <div><b>BUSINESS TYPE:</b> {{resultData?.consigneeAddress?.addrTypeId || '--' | uppercase}}</div>
                                        <div><b>ETA DELIVERY DATE:</b> {{ BOLd?.deliveryDate ? (BOLd?.deliveryDate | date:'MM-dd-yyyy') : '--' }}</div>
                                        <div><b>HOURS OF DELIVERY:</b>{{ resultData?.deliveryFromTime ? resultData.deliveryFromTime: '--' }} to {{ resultData?.deliveryToTime ?
                                            resultData.deliveryToTime: '--' }}</div>
                                        <div><b>DEL INSTRUCTIONS:</b> {{ resultData?.deliveryInstruction ? resultData.deliveryInstruction : '--' }}</div>
                                        <div>
                                            <b>CONSIGNEE:</b>
                                            <ng-container
                                                *ngIf="resultData?.consigneeAddress.extraServices && resultData?.consigneeAddress.extraServices.length > 0; else noServices">
                                                <ng-container
                                                    *ngFor="let extraService of resultData?.consigneeAddress.extraServices; let last = last">
                                                    {{ extraService }}{{ !last ? ',' : '' }}
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #noServices>--</ng-template>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="bill_freight_strip1">
                                    <b> BILL FREIGHT CHARGES TO THE FOLLOWING PARTY</b>
                                </div>
                                <div class="shipping_charges_strip1"
                                    style="text-transform: capitalize; line-height: 1.2">
                                    <b>SHIPCARTE  </b> <span *ngIf="BOLd && BOLd.carrierAccountNo"> ACCOUNT NO - {{BOLd?BOLd.carrierAccountNo?BOLd.carrierAccountNo:"":""}}</span><br>
                                    <b>{{resultData.carrierBolDisplayAddress?resultData.carrierBolDisplayAddress:'1033 JAYSON COURT, MISSISSAUGA, ON L4W 2P4, CANADA'}}</b>
                                </div>
                                <div class="received_text_section1" style="line-height: 1.4;">
                                    RECEIVED, subject to the classifications and lawfully filed tariffs in
                                    effect on the date of issue
                                    of this Bill Of Lading, the property described below and in apparent
                                    good order, except as noted
                                    (contents and condition of contents of packages unkown), marked,
                                    consigned, and destined, as indicated
                                    above which said carrier (the word carrier being understood throughout
                                    this contract as meaning any person
                                    or corporation in possession of the property under the contract) agrees
                                    to carry to its usual place of delivery
                                    of said destination, if on its route, otherwise to deliver to another
                                    carrier on the route to said destination.
                                    It is mutually agreed as to each carrier of all or any said property
                                    over all or any portion of said route to
                                    destination and as to each party at time interested in all or any of
                                    said property, that every service to be
                                    performed hereunder shall be subject to all the conditions not
                                    prohibited by law, whether printed or written,
                                    herein contained, including the conditions on the back or attached
                                    hereof, which are hereby agreed toby the
                                    shipper and accepted for himself and his assigns.
                                </div>
                            </div>
                            <div class="pro_details_table1">
                                <table>
                                    <tr class="yellow-header1" style="width: 100%;float: left; display: flex; justify-content: space-between;">
                                        <th style="width: 8%;padding-left:3px">QTY</th>
                                        <th style="width: 12%;">TYPE</th>
                                        <th style="width: 19%;">DIMENSION </th>        
                                        <th style="width: 15%;">WEIGHT</th>
                                        <th style="width: 20%"> COMMODITY DESCRIPTION</th>
                                        <th style="width: 12%;">CLASS</th>
                                        <th style="width: 7%;">NMFC</th>
                                        <th style="width: 9%;">HAZMAT</th>
                                    </tr>
                                    <ng-container *ngFor="let keyData of resultData ? resultData['shipmentItems'] : []">
                                        <tr style="padding: 0px 0px;width: 100%;float: left; background-color: #E2E7EE; display: flex; justify-content: space-between;" [ngStyle]="{'border-bottom': keyData?.hazMat ? '' : '2px solid #D4D4D4'}">
                                            <td style="width: 8%;padding-left:3px; ">{{keyData.qty}}</td>
                                            <td style="width: 12%; text-transform: capitalize;">
                                                {{keyData.packagingCode ? keyData.packagingCode :
                                                BOLd?.shipmentItemslengthType}}</td>
                                            <td style="width: 19%;"> {{keyData.length | number:'1.2-2'}} L X
                                                {{keyData.breadth | number:'1.2-2'}} W X {{keyData.height |
                                                number:'1.2-2'}} H</td>
                                            <!-- <td style="width: 35%;">{{keyData.description ? keyData.description
                                                : '--'}}</td> -->
                                            <td style="width: 15%;">
                                                {{keyData.totalWeight * keyData.qty | number:'1.2-2'}} {{keyData.wghtUnit}}
                                            </td>
                                            <td style="width: 20%;">{{keyData.description ? keyData.description : '--'}}</td>
                                            <td style="width: 12%;">{{keyData.freightClass ?
                                                keyData.freightClass : '--'}}
                                            </td>
                                            <td style="width: 7%;">{{keyData.nmfc ? keyData.nmfc : '--'}}</td>
                                            <td style="width: 9%;">{{keyData.hazMat ? 'Yes' : 'No'}}</td>
                                        </tr>
                                        <!-- <tr style="padding: 2px 0px;width: 100%;float: left;">
                                            <td
                                                style="width:100%;padding-left:3px; background-color: #E2E7EE77; font-size: 12px; font-family: poppins,sans-serif;">
                                                <b>Dimensions :</b> {{dynamicDimension.length | number:'1.2-2'}}
                                                L x {{dynamicDimension.width | number:'1.2-2'}} W x
                                                {{dynamicDimension.height | number:'1.2-2'}} H
                                            </td>
                                        </tr> -->
                                        <ng-container *ngIf="keyData?.hazMat">
                                            <tr style="padding: 0px 0px;width: 100%;float: left; background-color: #E2E7EE; display: flex; justify-content: flex-end;">
                                                <td style="width: 8%;"><b>TYPE</b></td>
                                                <td style="width: 12%;"><b>UN NUMBER</b></td>
                                                <td style="width: 22%;"><b>PROPER SHIPPING NAME</b></td>
                                                <td style="width: 15%;"><b>HAZARD CLASS</b></td>
                                                <td style="width: 15%;"><b>PACKAGE GROUP</b></td>
                                                <td style="width: 15%;"><b>PACKAGE TYPE</b></td>
                                            </tr>
                                            <tr style="padding: 0px 0px;width: 100%;float: left; background-color: #E2E7EE; display: flex; justify-content: flex-end;" [ngStyle]="{'border-bottom': keyData?.hazMat ? '2px solid #D4D4D4' : ''}">
                                                <td style="width: 8%;">{{keyData.unNumType}}</td>
                                                <td style="width: 12%;">{{keyData.unNumber}}</td>
                                                <td style="width: 22%;">{{keyData.properShippingName}}</td>
                                                <td style="width: 15%;">{{keyData.hazardClass}}</td>
                                                <td style="width: 15%;">{{keyData.packageGroup | uppercase}}</td>
                                                <td style="width: 15%;">{{keyData.hazardPackagingType | titlecase}}</td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </table>
                            </div>
                            <div class="shipment_size_box1">
                                <div style="width: 100%;float: left;padding: 0px 1px 5px 3px;">
                                    <div class="shipment_box_11">
                                        <div
                                            style="font-size: 12px; font-weight: 600; font-family: poppins,sans-serif;">
                                            TRAILER LOADED:</div>
                                        <div style="width: 43%;float: left; display: flex;">
                                            <input type="checkbox" id="" name="" value="" style="position: relative;">
                                            <label for="" style="font-size:10px !important; font-weight: 400; font-family: poppins,sans-serif;margin-top: 8px;
                                                margin-left: 5px;">
                                                By Shipper</label>
                                        </div>
                                        <div style="width: 43%;float: left; display: flex; align-items: center;">
                                            <input type="checkbox" id="" name="" value="" style="position: relative;">
                                            <label for="" style="font-size:10px !important;font-weight: 400; font-family: poppins,sans-serif;margin-top: 8px;
                                                margin-left: 5px;">
                                                By Driver</label>
                                        </div>
                                    </div>
                                    <div class="shipment_box_11">
                                        <div
                                            style="font-size: 12px; font-weight: 600; font-family: poppins,sans-serif;">
                                            FREIGHT COUNTED:</div>
                                        <div style="width: 43%;float: left; display: flex; align-items: center ;">
                                            <input type="checkbox" id="" name="" value="" style="position: relative;">
                                            <label for="" style="font-size:10px !important;font-weight: 400; font-family: poppins,sans-serif;     margin-top: 8px;
                                                margin-left: 5px;">
                                                By Shipper</label>
                                        </div>
                                        <div style="width: 43%;float: left; display: flex; align-items: center;">
                                            <input type="checkbox" id="" name="" value="" style="position: relative;">
                                            <label for="" style="font-size:10px !important;font-weight: 400; font-family: poppins,sans-serif;margin-top: 8px;
                                                margin-left: 5px;">
                                                By Driver</label>
                                        </div>
                                    </div>
                                    <div class="shipment_box_11"
                                        style="text-align: right;font-size: 14px; font-weight: 600; font-family: poppins,sans-serif;">
                                        <div class="orange_heading" style="font-size: 14px;">SHIPMENT SIZE
                                        </div>
                                        <div
                                            tyle="text-align: right;font-size: 10px; font-weight: 600; font-family: poppins,sans-serif;">


                                            <div
                                                tyle="text-align: right;font-size: 10px; font-weight: 600; font-family: poppins,sans-serif;">
                                                <div *ngIf="totalPackageCountArray?.length>0">
                                                    <ng-container
                                                        *ngFor="let item of totalPackageCountArray,let i=index">

                                                        <span *ngIf="i<=1">

                                                            {{item['count']}} {{ item['packageName']}},
                                                        </span>

                                                    </ng-container>
                                                </div>
                                                <div *ngIf="totalPackageCountArray?.length>0">
                                                    <ng-container
                                                        *ngFor="let item of totalPackageCountArray,let i=index">

                                                        <span *ngIf="i>1">

                                                            {{item['count']}} {{ item['packageName']}},
                                                        </span>

                                                    </ng-container>
                                                    {{dynamicDimension?.weight | number:'1.2-2'}}
                                                    {{weightType}}
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div style="width: 100%; display: flex;">
                                    <div style="width: 62%;float:left;">
                                        <div class="Declared_box1">
                                            <div *ngIf="BOLd?.isInsurance"
                                                style="font-size: 12px; font-weight: 600; font-family: poppins,sans-serif;">
                                                Declared value: ${{declaredValue}} &nbsp; &nbsp;&nbsp;
                                                &nbsp;"NOT TO BE INSURED BY CARRIER"</div>
                                            <div style="font-size: 10px; padding: 2px; line-height: 1.4;">
                                                Maximum liability of
                                                $2.00/lb or $4.41/kg computed on the total actual weight
                                                unless declared valuation states otherwise. please see
                                                additional terms and conditions on reverse.</div>
                                        </div>
                                        <div class="freight_collect_shipments_box1">
                                            <div
                                                style="font-size: 10px; font-weight: 600; font-family: poppins,sans-serif; padding: 4px;">
                                                FOR FREIGHT COLLECT SHIPMENTS</div>
                                        </div>
                                        <div class="consignor_signature_box1">
                                            <div style="padding:2px; line-height: 1.4;"> I hereby declare
                                                that the contents of
                                                this consignment are fully and accurately described above
                                                by proper shipping name and are classified, packed, marked
                                                and labeled, and are in all respects
                                                in proper condition for transport by rail, highway, water
                                                according to applicable international and
                                                national regulations</div>
                                            <div
                                                style="width:40%;float: left;padding: 0px; font-size: 10px; font-family: poppins,sans-serif; font-weight: 400;">
                                                CONSIGNOR SIGNATURE</div>
                                            <div class="signature_line1"></div>
                                        </div>
                                    </div>
                                    <div style="width: 38%;float: left;border-left: 1px solid #ccc;border-top: 1px solid #ccc;padding: 3px 0 0 4px;">
                                        <div class="p-0"
                                            style="font-size: 10px;font-weight: 600; font-family: poppins,sans-serif; padding: 7px; border-bottom: 1px solid #D4D4D4; line-height: 1.4;">
                                            <div style="font-weight: 400; font-size: 8px;">FOR INTERNATIONAL SHIPMENTS PLEASE INDICATE BELOW THE NAME
                                                AND PHONE NUMBER OF BROKER </div>
                                            <div style="font-size: 10px;font-weight: 600;padding: 4px 0 0 0;">
                                                BROKER NAME : {{BOLd?.brokerName}}</div>
                                            <div style="width: 50%;float: left;font-weight: 600;padding: 4px 0 0 0;">
                                                PHONE: {{BOLd?.Phone}}</div>
                                            <div
                                                style="width: 50%;float: left;font-weight: 600;padding: 4px 0 0 0; word-wrap: break-word;">
                                                EMAIL: {{BOLd?.brokerEmail}}</div>
                                            <!--  -->
                                            <!-- <div style="width: 50%;float: left;font-weight: 600;padding: 4px 0 0 0;"> carrier QuoteId : {{BOLd?.carrierQuoteId ?  BOLd?.carrierQuoteId : '--'}}</div> -->
                                        </div>
                                        <!-- <div *ngIf="!carrier" [ngStyle]="{'margin-bottom': (resultData?.consigneeAddress.extraServices && resultData?.consigneeAddress.extraServices.length > 0) ? '0' : '45px'}"
                                            style="width:100%;float:left; display: flex; justify-content: center;" id="carrierImageContainer">

                                        </div>
                                        <div *ngIf="carrier" style="text-align: center;">
                                            <img *ngIf="carrier" style="width: 85px;padding: 3px 0;"
                                            src="{{'data:image/jpeg;base64,'+BOLd.?carrierLogo}}"
                                            alt="{{BOLd?.carrier}}" />
                                        </div> -->
                                        <!-- <div
                                            style="font-size: 10px; font-weight: 600; font-family: poppins, sans-serif; padding: 7px; border-bottom: 1px solid #D4D4D4; line-height: 1.4;">
                                            SHIPPER:
                                            <ng-container
                                                *ngIf="resultData?.shipperAddress.extraServices && resultData?.shipperAddress.extraServices.length > 0; else noServices">
                                                <ng-container
                                                    *ngFor="let extraService of resultData?.shipperAddress.extraServices; let last = last">
                                                    {{ extraService }}{{ !last ? ',' : '' }}
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #noServices>--</ng-template>
                                        </div>
                                        <div [ngStyle]="{'padding-bottom': (resultData?.consigneeAddress.extraServices && resultData?.consigneeAddress.extraServices.length > 0) ? '0' : '70px'}"
                                            style="font-size: 10px; font-weight: 600; font-family: poppins, sans-serif; padding: 7px; border-bottom: 1px solid #D4D4D4; line-height: 1.4;">
                                            CONSIGNEE:
                                            <ng-container
                                                *ngIf="resultData?.consigneeAddress.extraServices && resultData?.consigneeAddress.extraServices.length > 0; else noServices">
                                                <ng-container
                                                    *ngFor="let extraService of resultData?.consigneeAddress.extraServices; let last = last">
                                                    {{ extraService }}{{ !last ? ',' : '' }}
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #noServices>--</ng-template>
                                        </div> -->
                                        <div
                                            style="width:100%;float:left; font-family: poppins,sans-serif; font-weight: 600; font-size: 12px;">
                                            <span>CARRIER QUOTEID:</span>
                                            {{BOLd?.carrierQuoteId}}
                                        </div>
                                        <div style="width: 100%;float: left; font-size: 12px; font-weight: 500;">
                                            <div
                                                style="width:100%;float: left;    font-weight: 500; font-family: poppins,sans-serif;">
                                                CARRIER SERVICE NAME
                                            </div>
                                            <div>{{BOLd?.carrier}}
                                                {{BOLd?BOLd.carrierServiceName?BOLd.carrierServiceName:"":""}}
                                            </div>
                                        </div>
                                        <div style="width: 100%;float: left; font-size: 11px; font-weight: 500;" *ngIf="BOLd?.ladingNo">
                                            <div
                                                style="width:100%;float: left;    font-weight: 500; font-family: poppins,sans-serif;">
                                                CARRIER REF NO.: <span><b>{{BOLd?.ladingNo ? BOLd?.ladingNo : '--'}}</b></span>
                                            </div>
                                        </div>
                                        <div style="width: 100%;float: left;">
                                            <div
                                                style="width: 59%;float: left;padding: 5px 4px 3px 0; font-size: 10px; font-weight: 400; font-family: poppins,sans-serif;">
                                                SIGNATURE:
                                            </div>
                                            <div class="signature_line"></div>
                                        </div>
                                        <div style="width: 100%;float: left;">
                                            <div
                                                style="width: 59%;float: left;padding: 5px 4px 3px 0; font-size: 10px; font-weight: 400; font-family: poppins,sans-serif;">
                                                DATE:
                                            </div>
                                            <div class="signature_line"></div>
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 100%; display: flex;">
                                    <div style="width: 62%; border: 1px solid #D4D4D4;">
                                        <div class="freight_collect_shipments_box1">
                                            <div
                                                style="font-size: 10px; font-weight: 600; font-family: poppins,sans-serif; padding: 4px; ">
                                                SHIPPER CERTIFICATION</div>
                                        </div>
                                        <div class="consignor_signature_box">
                                            <div
                                                style="font-size: 8px;font-weight: 400; font-family: poppins,sans-serif; line-height: 1.4; padding-left: 4px;  ">
                                                Subject to section 9 of conditions of applicable bill of
                                                lading. If this shipment is to be delivered to the
                                                consignee,
                                                without recourse on the consignor, the consignor shall sign
                                                the following statement. The carrier may decline to make
                                                delivery of this shipment without payment of freight and all
                                                other lawful charges.</div>
                                            <div
                                                style="width: 65%;float: left;margin-bottom: 10px; margin-top: 13px; padding-left: 4px; ">
                                                <div
                                                    style="width:43%;float: left;padding-top: 6px;font-size: 10px;font-weight: 400; font-family: poppins,sans-serif; ">
                                                    SHIPPERS NAME
                                                </div>
                                                <div
                                                    style="font-size: 10px;font-weight: 400; font-family: poppins,sans-serif; ">

                                                </div>
                                                <div class="signature_line1" style="margin-top: 18px;">
                                                </div>
                                            </div>
                                            <div style="width: 35%;float: left;">
                                                <div style="width:30%;float: left;padding: 0;">
                                                </div>
                                            </div>
                                            <div style="width: 65%;float: left; margin-top: 5px; padding-left: 4px;">
                                                <div
                                                    style="width:43%;float: left;padding: 4px;font-size: 10px;font-weight: 400; font-family: poppins,sans-serif; padding-left: 0px !important;">
                                                    SIGNATURE
                                                </div>
                                                <div class="signature_line1" style="margin-top: 18px;">
                                                </div>
                                            </div>
                                            <div style="width: 35%;float: left; margin-top: 10px;">
                                                <div
                                                    style="width:30%;float: left;padding: 0; font-size: 10px;font-weight: 400; font-family: poppins,sans-serif;">
                                                    DATE
                                                </div>
                                                <div class="signature_line1"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width: 38%;">
                                        <div class="freight_collect_shipments_box"
                                            style="width: 100%; background-color: #f7c765; float: left; padding: 0;">
                                            <div style="color: #000;font-size: 10px; font-weight: 600; font-family: poppins,sans-serif;  padding: 4px;">
                                                CONSIGNEE CERTIFICATION
                                            </div>
                                        </div>
                                        <div class="consignor_signature_box" style="padding-left: 4px;">
                                            <div
                                                style="font-size: 10px; font-weight: 400; font-family: poppins,sans-serif; padding: 3px; padding-left: 0px;float: left;">
                                                Product was received in apparent good order except as noted.
                                            </div>
                                            <div style="width: 65%;float: left; margin-bottom: 10px;  margin-top: 5px; display: flex; align-items: flex-end;">
                                                <div
                                                    style="width:43%;float: left;padding-top: 4px; font-size: 10px;font-weight: 400; font-family: poppins,sans-serif;">
                                                    CONSIGNOR NAME
                                                </div>
                                                <div
                                                    style="font-size: 10px;font-weight: 400; font-family: poppins,sans-serif;  ">

                                                </div>
                                                <div class="signature_line1" style="margin-top: 17px;">
                                                </div>
                                            </div>
                                            <div style="width: 35%;float: left;">
                                                <div style="width:30%;float: left;padding: 0;">
                                                </div>
                                            </div>
                                            <div style="width: 65%;float: left; margin-top: 10px;">
                                                <div
                                                    style="width:43%;float: left;padding: 0; font-size: 10px;font-weight: 400; font-family: poppins,sans-serif;">
                                                    SIGNATURE
                                                </div>
                                                <div class="signature_line1" style="margin-top: 10px;">
                                                </div>
                                            </div>
                                            <div style="width: 35%;float: left; margin-top: 10px;">
                                                <div
                                                    style="width:30%;float: left;padding: 0; font-size: 10px;font-weight: 400; font-family: poppins,sans-serif;">
                                                    DATE
                                                </div>
                                                <div class="signature_line1"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-bottom: 0px !important;">
                            <div class="col-7">
                                <div><strong>{{resultData.carrierBolDisplayAddress?resultData.carrierBolDisplayAddress:'1033 JAYSON COURT, MISSISSAUGA, ON L4W 2P4, CANADA'}}</strong>
                                </div>
                                <div style="font-size: 10px;"><span
                                        style="color:#FF8D00;font-weight: 600;">www.shipcarte.com</span>
                                    <span style="margin-left: 10px;">888.424.8878</span>
                                </div>
                            </div>
                            <!-- <div class="col-5 text-end">
                                <a style="margin-right: 8px;" href="https://www.linkedin.com/company/shipcarte-inc/"
                                    target="_blank">
                                    <img src="assets/img/shipment/Black-Linkedin.svg" width="17px"
                                        class="m-1 text-center">
                                </a>
                                <a style="margin-right: 8px;" href="https://www.instagram.com/shipcarte/?hl=en%C2%A0"
                                    target="_blank">
                                    <img src="assets/img/shipment/Black-Insta.svg" width="17px" class="m-1 text-center">
                                </a>
                                <a style="margin-right: 8px;" href="https://twitter.com/shipcarte?lang=en%C2%A0"
                                    target="_blank">
                                    <img src="assets/img/shipment/Black-Twitter.svg" width="17px"
                                        class="m-1 text-center">
                                </a>
                                <a style="margin-right: 8px;" href="https://www.facebook.com/ShipCarte/"
                                    target="_blank">
                                    <img src="assets/img/shipment/Black-Facebook.svg" width="17px"
                                        class="m-1 text-center">
                                </a>
                            </div> -->
                        </div>
                        <span class="break-page"></span>
                        <div class="bol-page_section second-sec"
                            style="  background: #fff;padding: 0px 5px;height: auto;border: 1px solid #ccc;float: left; font-family: poppins,sans-serif;">
                            <div class="text-center" style="background-color: #F3F5F7;">
                                <h3 class="m-0" style="font-size: 12px !important;margin-bottom:0 !important">UNIFORM
                                    STRAIGHT BILL OF LADING</h3>
                                <h5 class="mb-0"><b style="font-size: 14px;">Terms & Conditions</b></h5>

                            </div>
                            <div class="row ternsAndCondition">
                                <div class="col-12">
                                    <div>
                                        <h5 class="m-0"><b>1. UNIFORM TERMS OF CARRIAGE TO APPLY</b></h5>
                                        <p>
                                            This contract for carriage of goods includes all uniform terms
                                            of carriage enacted for the carriage
                                            of general freight pursuant to any statute, regulation or by any
                                            lawful authority, which is in force and effect
                                            in the jurisdiction of origin of this contract at the time of
                                            shipment.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>2. NOTICE OF CLAIM</b></h5>
                                        <p>
                                            (1) No carrier is liable for loss, damage or delay to any goods
                                            carried under the Bill of Lading unless notice
                                            thereof setting out the particulars of the origin, destination
                                            and date of shipment of the goods and the
                                            estimated amount claimed in respect of such loss, damage or
                                            delay is given in writing to the originating
                                            carrier or to the delivering carrier within Sixty (60) days
                                            after delivery of the goods, or in the case of failure
                                            to make delivery, within Nine (9) months of the date of
                                            shipment. (2) The final statement of claim must be filed
                                            within Nine (9) months from the date of shipment together with a
                                            copy of the paid freight bill.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>3. RECEIPT OF GOODS</b></h5>
                                        <p>
                                            The carrier acknowledges receipt of the goods itemized on the
                                            face of the within Bill of Lading by the consignor in
                                            apparent good order and condition.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>4. CONSIGNOR’S WARRANTIES AS TO PREPARATION OF
                                                SHIPMENT</b></h5>
                                        <p>
                                            The consignor warrants to the carrier:
                                            (1) The cartons, containers and goods have been marked to
                                            identify the consignee, the consignee’s address, number of
                                            pieces
                                            and any delivery instructions and that such markings are
                                            consistent with the markings and instructions of this Bill of
                                            Lading.
                                            (2) The goods have been properly packaged and prepared to
                                            withstand those risks of damage necessarily incidental to
                                            transportation.
                                            (3) If these goods are dangerous goods, the goods and this bill
                                            of lading havebeen prepared to comply with all federal and
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>5. LIMITATION OF LIABILITY</b></h5>
                                        <p>
                                            (1) Unless the consignor has declared a value of the goods on
                                            the face of the Bill of Lading, the amount of any loss or damage
                                            for which
                                            the carrier is liable, whether or not such loss or damage
                                            results from negligence, shall be the lesser of: a) the value of
                                            the goods at the
                                            place and time of shipment, including freight and other charges
                                            if paid, and b) $4.41 per kilogram computed on the total weight
                                            of the shipment.
                                            (2) The parties agree that the carrier cannot reasonably be
                                            aware of the consequences of and the costs accruing to
                                            the consignor, consignee, owner or any other party in the event
                                            of the loss of use of the goods due to the late,
                                            delayed or non-delivery of the goods, or the whole or partial
                                            loss or destruction of all or any part of the goods
                                            however caused. Accordingly, the carrier is not liable for the
                                            indirect, consequential or incidental loss occurring
                                            to any party because of late, delayed or non-delivery of, loss
                                            of or damage to the goods. (3) If the consignor has
                                            declared a value of the goods on the face of the contract of
                                            carriage, the amount of any loss or damage for which the
                                            carrier is liable shall not exceed the declared value.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>6. EXCEPTIONS FROM LIABILITY</b></h5>
                                        <p>
                                            The carrier shall not be liable for loss, damage or delay to any
                                            of the goods described in the contract of carriage caused by an
                                            act of God, the Queen’s or public enemies, riots, strikes, a
                                            defect or inherent vice in the goods, an act or default of the
                                            consignor, owner or consignee, authority of law, quarantine or
                                            difference in weights of commodities caused by natural
                                            shrinkage.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>7. DELAY</b></h5>
                                        <p>
                                            No carrier is bound to carry goods by any particular public
                                            truck or in time for any
                                            particular market or otherwise than with due dispatch, unless by
                                            agreement that is
                                            specifically endorsed in the contract of carriage and signed by
                                            the parties.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>8. DANGEROUS GOODS</b></h5>
                                        <p>
                                            Every person, whether as principal or agent, shipping dangerous
                                            goods without previous full disclosure to the carrier as
                                            required by law shall indemnify the carrier against all loss,
                                            damage or delay caused by the failure to disclose, and such
                                            goods may be warehoused at the consignor’s sole risk andexpense.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>9. L IABILITY FOR PAYMENT</b></h5>
                                        <p>
                                            Regardless of any instructions provided for the payment of
                                            freight charges, the consignor shall, in the event the carrier
                                            is unable to collect in accordance with those instructions, be
                                            responsible for all freight charges together with all costs
                                            incurred as a result of inability to collect transportation
                                            charges in accordance with the consignor’s instructions.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>10. LANGUAGE</b></h5>
                                        <p>
                                            The parties hereby confirm their express wish that this contract
                                            and all related documents be prepared in the English language
                                            only. Les parties reconnaissent avoir exigé que le présente
                                            contrat et tous les documents connexes soient rédigés en anglais
                                            seulement.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>11. ENTIRE CONTRACT</b></h5>
                                        <p>
                                            The uniform terms of carriage and conditions herein form the
                                            entire contract between the parties, which shall not be modified
                                            without the written consent of both parties.
                                        </p>
                                    </div>

                                    <div>
                                        <img style="width: 250px; padding-top: 12px;" src={{BOLd?.shipcarteLogoSmall}}
                                            alt="" />
                                        <div>
                                            ORDER NO.<span
                                                style="color:#FF8D00; font-size: 12px !important; font-weight: 400;">{{BOLd?.orderNumber}}</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-6">
                                    <div>
                                        <h5 class="m-0"><b>1. LES CONDITIONS UNIFORMES EN MATIÈRE DE
                                                TRANSPORT À APPLIQUER</b></h5>
                                        <p>
                                            Le présent contrat aux fins de transport de marchandises
                                            comprend toutes les conditions uniformes prévues en matière de
                                            transport de fret général en vertu de toute loi, tout règlement
                                            ou pouvoir légal en vigueur dans le ressort d’origine du présent
                                            contrat au moment de l’expédition.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>2. AVIS DE RÉCLAMATION</b></h5>
                                        <p>
                                            (1) Le transporteur ne saurait être tenu responsable de la
                                            perte, des dommages subis, ni du retard de livraison des
                                            marchandises transportées en vertu de la lettre de fret à moins
                                            qu’un avis à cet égardétablissant des dispositions particulières
                                            quant à l’origine, la destination et la date de l’envoi des
                                            marchandises et à une estimation du montant réclamé en vertu de
                                            tels perte, dommage ou retard ne soit remis par écrit au
                                            transporteur d’origine ou au transporteur livreur dans les
                                            soixante (60) jours après la livraison des marchandises ou, en
                                            cas de défaut de livraison, dans les neuf (9) mois à compter de
                                            la date d’expédition. (2) L’énoncé final de réclamation doit
                                            être présenté dans les neuf (9) mois à compter de la date
                                            d’expédition, accompagné d’un exemplaire de la lettre de fret
                                            payée.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>3. RÉCEPTION DES MARCHANDISES</b></h5>
                                        <p>
                                            Le transporteur accuse réception par l’expéditeur, en bon état
                                            et bonne condition apparents, desmarchandises détaillées au
                                            recto de la lettre de fret ci-jointe.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>4. GARANTIES DE L’EXPÉDITEUR À L’ÉGARD DE LA
                                                PRÉPARATION DE L’ENVOI</b></h5>
                                        <p>
                                            L’expéditeur garantit au transporteur que :
                                            (1) Les cartons, conteneurs et marchandises ont été marqués de
                                            façon à identifier le destinataire, son adresse, le nombre de
                                            pièces ainsi que toute directive en matière de livraison et que
                                            de telles marques sont conformes aux indications et directives
                                            de la lettre de fret. (2) Les marchandises ont été adéquatement
                                            emballées et préparées afin de pouvoir résister à des risques ou
                                            des dommages susceptibles de se produire pendant leur transport.
                                            (3) Si les articles sont des marchandisesdangereuses, ils
                                            doivent, ainsi que la présente lettre de fret, avoir été
                                            préparés de façon à être conformes à toutes les lois et à tous
                                            les règlements fédéraux et provinciaux applicables au transport
                                            de marchandises dangereuses.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>5. LIMITE DE RESPONSABILITÉ</b></h5>
                                        <p>
                                            (1) À moins que l’expéditeur n’ait déclaré la valeur des
                                            marchandises au recto de la lettre de fret, le montant relatif à
                                            toute perte ou dommage duquel le transporteur est responsable,
                                            que lesdits perte ou dommage soient ou non le fruit d’une
                                            négligence, doit être le moindre de : a) la valeur des
                                            marchandises à l’emplacement et au moment de l’expédition, y
                                            compris les frais de transport et les autres frais s’ils ont été
                                            payés et b) 4,41 $ par kilogramme calculé d’après le poids total
                                            de l’envoi. (2) Les parties acceptent que le transporteur ne
                                            puisse raisonnablement être conscient des conséquences et des
                                            coûts imputables à l’expéditeur, au destinataire, au
                                            propriétaire ou à toute autre partie en cas de perte de
                                            jouissance des marchandises découlant d’un retard, d’un report
                                            ou d’un défaut de livraison des marchandises, ni de la perte ou
                                            de la destruction, peu importe leur cause, en tout ou en partie
                                            de toutes les marchandises, voire de n’importe quelle portion de
                                            celles-ci. Par conséquent, le transporteur n’est pas responsable
                                            de la perte indirecte ou imprévue subie par l’une ou l’autre
                                            partie en raison d’un retard, d’un report ou d’un défaut de
                                            livraison, d’une perte des marchandises, ni de dommages subis
                                            par ces dernières. (3) Si l’expéditeur a déclaré la valeur des
                                            marchandises au recto du contrat de transport, le montant
                                            relatif à toute perte ou dommage duquel le transporteur est
                                            responsable ne doit pas excéder lavaleur déclarée.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>6. EXCEPTIONS À L’ÉGARD DE LA RESPONSABILITÉ</b>
                                        </h5>
                                        <p>
                                            Le transporteur ne peut être tenu responsable de la perte de
                                            n’importe quelle marchandises décrite dans le contrat de
                                            transport, du retard de leur livraison ni de dommages subis
                                            imputables à une catastrophe naturelle, à des actes d’ennemis de
                                            la Reine ou du public, des émeutes, des grèves, un défaut ou un
                                            vice propre aux marchandises, un acte ou une infraction de
                                            l’expéditeur, du propriétaire ou du destinataire, une décision
                                            d’un pouvoir législatif, une quarantaine ou une différence du
                                            poids des marchandises à la suite d’une perte naturelle de la
                                            masse.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>7. RETARD</b></h5>
                                        <p>
                                            Le transporteur n’est tenu de faire preuve que de diligence
                                            raisonnable pour ce qui est de transporter des marchandises à
                                            bord d’un camion public ou à temps pour l’ouverture d’un marché
                                            quelconque, à moins qu’une entente dûment consignée dans le
                                            présent contrat et signée par les parties ne le prévoie
                                            autrement.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>8. MARCHANDISES DANGEREUSES</b></h5>
                                        <p>
                                            Chaque personne, qu’elle soit mandant ou mandataire, qui expédie
                                            des marchandises
                                            dangereuses sans en informer le transporteur au préalable,
                                            conformément aux dispositions légales, exonère ce dernier contre
                                            toute perte, dommage ou retard découlant du défaut de
                                            divulgation. Par ailleurs, les marchandises pourront être
                                            entreposées au risque et aux frais de l’expéditeur.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>9. OBLIGATION DE PAIEMENT</b></h5>
                                        <p>
                                            Sans égard aux directives relatives au paiement des frais de
                                            transport, l’expéditeur doit défrayer la totalité des frais de
                                            transport advenant que le transporteur ne soit pas en mesure de
                                            percevoir lesdits frais auprès du destinataire conformément aux
                                            directives formulées par l’expéditeur.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>10. LANGUE</b></h5>
                                        <p>
                                            The parties hereby confirm their express wish that this contract
                                            and all related documents be prepared in the French language
                                            only. Les parties reconnaissent avoir exigé que le présente
                                            contrat et tous les documents connexes soient rédigés en
                                            français seulement.
                                        </p>
                                    </div>
                                    <div>
                                        <h5 class="m-0"><b>11. INTÉGRALITÉ DU CONTRAT</b></h5>
                                        <p> Les conditions uniformes en matière de transport énoncées aux
                                            présentes constituent la totalité de l’entente convenue entre
                                            les parties. Cette dernière ne peut être modifiée sans le
                                            consentement écrit desparties.
                                        </p>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-7">
                                <div><strong>{{resultData.carrierBolDisplayAddress?resultData.carrierBolDisplayAddress:'1033 JAYSON COURT, MISSISSAUGA, ON L4W 2P4, CANADA'}}</strong>
                                </div>
                                <div><span style="color:#FF8D00;font-weight: 600;">www.shipcarte.com</span>
                                    <span style="margin-left: 10px;">888.424.8878</span>
                                </div>
                            </div>
                            <div class="col-5 text-end">
                                <a style="margin-right: 8px;" href="https://www.linkedin.com/company/shipcarte-inc/"
                                    target="_blank">
                                    <img src="assets/img/shipment/Black-Linkedin.svg" width="17px"
                                        class="m-1 text-center">
                                </a>
                                <a style="margin-right: 8px;" href="https://www.instagram.com/shipcarte/?hl=en%C2%A0"
                                    target="_blank">
                                    <img src="assets/img/shipment/Black-Insta.svg" width="17px" class="m-1 text-center">
                                </a>
                                <a style="margin-right: 8px;" href="https://twitter.com/shipcarte?lang=en%C2%A0"
                                    target="_blank">
                                    <img src="assets/img/shipment/Black-Twitter.svg" width="17px"
                                        class="m-1 text-center">
                                </a>
                                <a style="margin-right: 8px;" href="https://www.facebook.com/ShipCarte/"
                                    target="_blank">
                                    <img src="assets/img/shipment/Black-Facebook.svg" width="17px"
                                        class="m-1 text-center">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </body>

            </html>
        </div>
    </div>
</div>
<app-spinner></app-spinner>